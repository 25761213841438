import { useCallback } from 'react';
import { getSelectedColumnsInEn, getTranslatedColumnsInCorrectOrder } from 'src/utils';
import { useApiError, useAppData, useLoading } from 'src/providers';
import { useSnackbar } from 'notistack';
import { sendEmailClientsAndTradingDetails } from '../AllClientsAndTradingService';
import { reportEnColumnsReportId } from '../AllClientsAndTradingModel';
import { trackAmplitudeData } from '../../../../services/amplitudeService';

const useSendEmailByClient = ({ toDate, fromDate, selected, reportColumns, partnershipCode }: { toDate: Date; fromDate: Date; selected: string[]; reportColumns: any[]; partnershipCode: string }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setIsLoading } = useLoading();
  const { userData, translations } = useAppData();
  const { addError } = useApiError();

  return useCallback(async () => {
    const loginData = JSON.parse(userData);
    setIsLoading(true);

    try {
      trackAmplitudeData('partnerPortal_action_report', {
        type: 'SendByEmail',
        report_name: 'All Clients And Trading Details',
      });

      const tableColumns = getSelectedColumnsInEn(reportEnColumnsReportId, selected, reportColumns);
      await sendEmailClientsAndTradingDetails({
        partnershipCode,
        partnerId: loginData.partnerID,
        month: fromDate.getMonth() + 1,
        year: fromDate.getFullYear(),
        day: fromDate.getDate(),
        endMonth: toDate.getMonth() + 1,
        endYear: toDate.getFullYear(),
        endDay: toDate.getDate(),
        convertToCurrency: 'USD',
        requesterAccountGuid: loginData.defaultAccount,
        reportEmailRequest: {
          email: '',
          startDate: fromDate,
          endDate: toDate,
          includeTotal: true,
          tableColumns,
          translatedTableColumns: getTranslatedColumnsInCorrectOrder(selected, reportColumns),
        },
      });

      enqueueSnackbar(`${JSON.parse(translations).text_1640} ${loginData.userEmail}.`, {
        variant: 'success',
      });
    } catch (error) {
      addError(JSON.stringify(error.response));
    } finally {
      setIsLoading(false);
    }
  }, [userData, partnershipCode, fromDate, toDate, setIsLoading, selected, reportColumns, enqueueSnackbar, translations, addError]);
};

export default useSendEmailByClient;
