import { useCallback } from 'react';
import { useApiError, useAppData } from 'src/providers';
import { getAllClientsAndTradingReports } from '../AllClientsAndTradingService';
import { ReportModel } from '../../../../models';
import { trackAmplitudeData } from '../../../../services/amplitudeService';

const useGetReports = ({
  toDate,
  fromDate,
  setReports,
  showReports,
  setIsLoading,
  setShowReports,
}: {
  toDate: Date;
  fromDate: Date;
  showReports: boolean;
  setIsLoading: (data: boolean) => void;
  setShowReports: (data: boolean) => void;
  setReports: (data: ReportModel[]) => void;
}) => {
  const { addError } = useApiError();
  const { userData } = useAppData();

  return useCallback(async () => {
    const loginData = JSON.parse(userData);
    setIsLoading(true);

    try {
      trackAmplitudeData('partnerPortal_action_report', {
        type: 'Requested',
        report_name: 'All Clients and Trading',
      });

      const response = await getAllClientsAndTradingReports(loginData.partnerID, fromDate.getMonth() + 1, fromDate.getFullYear(), fromDate.getDate(), toDate.getMonth() + 1, toDate.getFullYear(), toDate.getDate(), 'USD', loginData.defaultAccount);

      setReports(response.data);

      if (!showReports) {
        setShowReports(true);
      }
    } catch (error) {
      setReports([]);
      addError(JSON.stringify(error.response));
    } finally {
      setIsLoading(false);
    }
  }, [userData, setIsLoading, fromDate, toDate, setReports, showReports, setShowReports, addError]);
};

export default useGetReports;
