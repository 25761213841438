export interface PositionsRequestModel {
  partnershipCodes: string[];
  fromDate: string;
  toDate: string;
  pageNumber: number;
  login?: string[];
}

export interface PositionsReport {
  closedLots: number;
  closedPnL: number;
  closedTrades: number;
  closedVolumeUSD: number;
  login: number;
  openLots: number;
  openPnL: number;
  openTrades: number;
  openedVolumeUSD: number;
  partnershipCode: string;
}

export interface OpenClosedPositionsEmailReportRequest {
  reportPartnershipClientsTradingRequest: {
    fromDate: string;
    toDate: string;
    partnershipCodes: string[];
    pageNumber: number;
    login: string[];
  };
  reportEmailRequest: {
    email: null | string;
    includeTotal: boolean;
    tableColumns: string[];
    translatedTableColumns: string[];
    startDate: string | null;
    endDate: string | null;
  };
}

export const reportEnColumns = [
  'partnership',
  'login',
  'volume (usd) (open positions)',
  'lots (open positions)',
  'count of trades (open positions)',
  'p/l (open positions)',
  'volume (usd) (closed positions)',
  'lots (closed positions)',
  'count of trades (closed positions)',
  'p/l (closed positions)',
];
