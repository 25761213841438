import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { groupBy, mapValues, omit } from 'lodash';
import { Button, Grid, Paper } from '@material-ui/core';
import moment from 'moment';
import { ReportDetailsModel, ValueLabel } from '../../../../models';
import { useAppData } from '../../../../providers';
import { dateObjectToMonthYearString, getTranslation } from '../../../../utils';
import { useStyles } from '../styles';
import { ColumnsSelect, ExportButtons, FieldLabel, MobileReportTitleWithBackButton, MobileTableView, PrintButton, SendByEmail } from '../../../../components';
import { useCommonStyles } from '../../../../styles';
import { DetailedTable } from './CommissionsDialog/DetailedTable';
import useSendEmailByClient from '../hooks/useSendEmailByClient';

interface Props {
  toDate: Date;
  fromDate: Date;
  title: any;
  subTitle: ReactNode;
  dateFrom: Date;
  data: ReportDetailsModel[];
  partnershipCode: string;
  onBackClick: () => void;
}

export const MobileCommissionsDetailedTable: FC<Props> = ({ toDate, fromDate, title, subTitle, dateFrom, data, partnershipCode, onBackClick }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [groupByOption, setGroupByOption] = useState<'Login' | 'Group' | 'Symbol' | undefined>(undefined);
  const { translations } = useAppData();
  const [translation, setTranslation] = useState<any>([]);
  const columnNames = useMemo(() => [translation.text_6634, translation.text_6635, translation.text_6636, translation.text_6637, translation.text_6638, translation.text_6639, translation.text_6640, translation.text_6641], [translation]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(columnNames);

  useEffect(() => {
    if (columnNames.length === 8) {
      setSelectedColumns(columnNames.slice(0, 7));
    }
  }, [columnNames]);

  const handleSelectChange = (value: string[]) => {
    setSelectedColumns(value);
  };

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  const exportReportName = (): string => {
    let reportName = translation.text_6035;
    const dateString = dateObjectToMonthYearString(dateFrom);
    reportName += dateString ? ` ${getTranslation(translation, moment(dateString).format('MMMM'))} ${moment(dateString).format('YYYY')}` : '';

    return reportName;
  };

  const renderTableData = (): string[][] => {
    const rows: string[][] = [];
    data.forEach((row) => {
      const tableRow = [
        row.Login ? row.Login.toString() : '-',
        row.ClientName ? row.ClientName : '-',
        row.Group ? row.Group : '-',
        row.Symbol ? row.Symbol : '-',
        row.RebateUSDLocal || row.RebateUSDLocal === 0 ? row.RebateUSDLocal.toFixed(2) : '-',
        row.VolumeUSD || row.VolumeUSD === 0 ? row.VolumeUSD.toFixed(2) : '-',
        row.VolumeLots || row.VolumeLots === 0 ? row.VolumeLots.toFixed(2) : '-',
        row.PaymentUSD || row.PaymentUSD === 0 ? row.PaymentUSD.toFixed(2) : '-',
      ];
      rows.push(tableRow);
    });

    return rows;
  };

  const renderRows = useCallback(
    (rowsData: any): any[] => {
      const result: any[] = [];

      if (rowsData.length === 0) {
        const rowData: ValueLabel[] = [];
        columnNames.forEach((columnName) => {
          const isSelected = selectedColumns.includes(columnName);
          if (isSelected) {
            rowData.push({
              value: '',
              label: columnName,
            });
          }
        });
        result.push(rowData);
      }

      rowsData.forEach((row: any) => {
        const rowData: ValueLabel[] = [];

        if (selectedColumns.includes(columnNames[0])) {
          rowData.push({
            value: row.Login ? row.Login : '-',
            label: columnNames[0],
          });
        }
        if (selectedColumns.includes(columnNames[1])) {
          rowData.push({
            value: row.ClientName ? row.ClientName : '-',
            label: columnNames[1],
          });
        }
        if (selectedColumns.includes(columnNames[2])) {
          rowData.push({
            value: row.Group ? row.Group : '-',
            label: columnNames[2],
          });
        }
        if (selectedColumns.includes(columnNames[3])) {
          rowData.push({
            value: row.Symbol ? row.Symbol : '-',
            label: columnNames[3],
          });
        }
        if (selectedColumns.includes(columnNames[4])) {
          rowData.push({
            value: row.RebateUSDLocal ? `$${row.RebateUSDLocal.toFixed(2)}` : '-',
            label: columnNames[4],
          });
        }
        if (selectedColumns.includes(columnNames[5])) {
          rowData.push({
            value: row.VolumeUSD ? row.VolumeUSD.toFixed(2) : '-',
            label: columnNames[5],
          });
        }
        if (selectedColumns.includes(columnNames[6])) {
          rowData.push({
            value: row.VolumeLots ? row.VolumeLots.toFixed(2) : '-',
            label: columnNames[6],
          });
        }
        if (selectedColumns.includes(columnNames[7])) {
          rowData.push({
            value: row.PaymentUSD ? `$${row.PaymentUSD.toFixed(2)}` : '-',
            label: columnNames[7],
          });
        }

        result.push(rowData);
      });

      return result;
    },
    [selectedColumns, columnNames],
  );

  const renderGroupedRows = useCallback(
    (array: any[]): any[] => {
      const result: any[] = [];

      array.forEach((row: any) => {
        const subRows = renderRows(row.subRows);

        let groupByValue = '';
        let groupByLabel = '';

        if (groupByOption === 'Login') {
          groupByLabel = translation.text_6001;
          groupByValue = row.Login;
        }

        if (groupByOption === 'Group') {
          groupByLabel = translation.text_6469;
          groupByValue = row.Group;
        }

        if (groupByOption === 'Symbol') {
          groupByLabel = translation.text_1013;
          groupByValue = row.Symbol;
        }

        result.push({
          groupByLabel,
          groupByValue,
          rows: subRows,
        });
      });

      return result;
    },
    [renderRows, groupByOption, translation],
  );

  const checkGroupByAndRenderRows = useCallback((): any[] => {
    let result: any[];

    if (groupByOption) {
      const grouped = mapValues(groupBy(data, groupByOption), (clist) => clist.map((row) => omit(row, groupByOption)));

      const resultArray = Object.keys(grouped).map((index) => ({
        Login: groupByOption === 'Login' ? index : '',
        ClientName: '-',
        Group: groupByOption === 'Group' ? index : '',
        Symbol: groupByOption === 'Symbol' ? index : '',
        subRows: grouped[index],
      }));
      result = renderGroupedRows(resultArray);
    } else {
      result = renderRows(data);
    }

    return result;
  }, [data, groupByOption, renderRows, renderGroupedRows]);

  const sendReportByEmail = useSendEmailByClient({
    toDate,
    fromDate,
    selected: selectedColumns,
    reportColumns: columnNames,
    partnershipCode,
  });

  return (
    <Grid container direction="column" className={classes.root}>
      <MobileReportTitleWithBackButton title={title} onBackClick={onBackClick} />
      <Grid item className={classes.subTitle}>
        {subTitle}
      </Grid>
      <Grid container direction="row" alignContent="center" alignItems="center" justify="center" className={classes.mobileButtons} spacing={1}>
        <Grid item>
          <ExportButtons exportFileName={exportReportName()} columns={columnNames} exportTableData={renderTableData()} fontSize={6} isEnabled reportNameInEn="Commissions By Client Details" />
        </Grid>
        <Grid item>
          <SendByEmail sendEmail={sendReportByEmail} translation={translation} title={exportReportName()} content={<MobileTableView list={checkGroupByAndRenderRows()} grouped={groupByOption !== undefined} hasNextButton={false} />} isEnabled />
        </Grid>
        <Grid item>
          <PrintButton translation={translation} title={exportReportName()} columnNumber={selectedColumns.length || 0} isEnabled className={classes.printButton} reportNameInEn="Commissions By Client Details">
            <DetailedTable translation={translation} groupByOption={groupByOption} columnNames={columnNames} selectedColumns={selectedColumns} rows={data} />
          </PrintButton>
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
        <Grid container direction="column" className={classes.margin}>
          <Grid item xs={12}>
            <FieldLabel label={translation.text_5718} />
            <Grid container direction="row">
              <Grid item>
                <Button
                  variant="outlined"
                  className={groupByOption === 'Login' ? classes.selectedGroup : ''}
                  classes={{
                    root: `${commonClasses.buttonContained} ${classes.login}`,
                  }}
                  onClick={() => setGroupByOption('Login')}
                >
                  {translation.text_6001}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  className={groupByOption === 'Group' ? classes.selectedGroup : ''}
                  classes={{
                    root: `${commonClasses.buttonContained} ${classes.symbolCategory}`,
                  }}
                  onClick={() => setGroupByOption('Group')}
                >
                  {translation.text_6469}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  className={groupByOption === 'Symbol' ? classes.selectedGroup : ''}
                  classes={{
                    root: `${commonClasses.buttonContained} ${classes.symbol}`,
                  }}
                  onClick={() => setGroupByOption('Symbol')}
                >
                  {translation.text_1013}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <ColumnsSelect reportColumns={columnNames} selectedColumns={selectedColumns} handleSelectColumns={handleSelectChange} />
        </Grid>
        <MobileTableView list={checkGroupByAndRenderRows()} grouped={groupByOption !== undefined} hasNextButton={false} />
      </Paper>
    </Grid>
  );
};
