import React, { FC, useCallback, useState, useEffect } from 'react';
import { Button, Divider, Grid, List, ListItem } from '@material-ui/core';
import { useStyles } from './styles';
import {
  ClientLinksIcon,
  ClientPortalIcon,
  // MarketingHubIcon
} from '../CustomIcons';
import { PartnerPartnership } from '../../models';
import { getPartnerPartnerships } from '../../pages';
import { PartnershipLinkDialog } from '../PartnershipLinkDialog';
import { useApiError, useAppData } from '../../providers';
import { defaultLocale } from '../../utils';

export const QuickLinks: FC = () => {
  const classes = useStyles();
  const { addError } = useApiError();
  const [partnerships, setPartnerships] = useState<PartnerPartnership[]>();
  const [linkDialogTrigger, setLinkDialogTrigger] = useState<number | undefined>(undefined);
  const [translation, setTranslation] = useState<any>([]);
  const { clientPortalRedirectUrl, translations, locale } = useAppData();

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  const handleClientLinksClick = useCallback(async () => {
    try {
      const response = await getPartnerPartnerships(false);
      setPartnerships(response.data);
      setLinkDialogTrigger(Math.random);
    } catch (error) {
      addError(JSON.stringify(error.response));
    }
  }, [addError]);

  return (
    <>
      <Grid container direction="column" className={classes.root}>
        <Grid item>
          <Divider variant="middle" className={classes.divider} />
        </Grid>
        <List component="nav">
          <ListItem className={classes.listItem}>
            <Button variant="outlined" className={classes.linkButtons} onClick={handleClientLinksClick} startIcon={<ClientLinksIcon />}>
              {translation.text_6232}
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              variant="outlined"
              className={classes.linkButtons}
              href={clientPortalRedirectUrl ? (locale === defaultLocale ? clientPortalRedirectUrl : `${clientPortalRedirectUrl}/${locale}`) : ''}
              target="_blank"
              startIcon={<ClientPortalIcon />}
            >
              {translation.text_6233}
            </Button>
          </ListItem>
          {/* <ListItem className={classes.listItem}>
            <Button variant="outlined" className={classes.linkButtons} startIcon={<MarketingHubIcon />}>{translation.text_6234}</Button>
          </ListItem> */}
        </List>
      </Grid>
      <PartnershipLinkDialog
        partnerships={partnerships}
        trigger={linkDialogTrigger}
        handleClose={() => {
          setLinkDialogTrigger(undefined);
        }}
      />
    </>
  );
};
