import React, { FC, useState, useMemo } from 'react';
import useSendEmailByClient from 'src/pages/Reports/CommissionsByClient/hooks/useSendEmailByClient';
import { ReportDetailsModel } from 'src/models';
import moment from 'moment';
import { ReportDetailsDialog } from '../../../../../components';
import { dateObjectToMonthYearString, getTranslation } from '../../../../../utils';
import { DetailedTable } from './DetailedTable';

interface Props {
  toDate: Date;
  fromDate: Date;
  translation: any;
  title: any;
  subTitle: any;
  dateFrom: Date;
  data: ReportDetailsModel[];
  partnershipCode: string;
  trigger: number | undefined;
  handleCancel: () => void;
}

export const CommissionDialog: FC<Props> = ({ toDate, fromDate, title, subTitle, dateFrom, data, trigger, handleCancel, translation, partnershipCode }) => {
  const [groupBy, setGroupBy] = useState<'Login' | 'Group' | 'Symbol' | undefined>(undefined);
  const columnNames = useMemo(() => [translation.text_6634, translation.text_6635, translation.text_6636, translation.text_6637, translation.text_6638, translation.text_6639, translation.text_6640, translation.text_6641], [translation]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(columnNames);

  const handleSelectChange = (value: string[]) => {
    setSelectedColumns(value);
  };

  const exportReportName = (): string => {
    let reportName = translation.text_6035;
    const dateString = dateObjectToMonthYearString(dateFrom);
    reportName += dateString ? ` ${getTranslation(translation, moment(dateString).format('MMMM'))} ${moment(dateString).format('YYYY')}` : '';

    return reportName;
  };

  const renderTableData = (): string[][] => {
    const rows: string[][] = [];
    data.forEach((row) => {
      const tableRow = [
        row.Login ? row.Login.toString() : '-',
        row.ClientName ? row.ClientName : '-',
        row.Group ? row.Group : '-',
        row.Symbol ? row.Symbol : '-',
        row.RebateUSDLocal || row.RebateUSDLocal === 0 ? row.RebateUSDLocal.toFixed(2) : '-',
        row.VolumeUSD || row.VolumeUSD === 0 ? row.VolumeUSD.toFixed(2) : '-',
        row.VolumeLots || row.VolumeLots === 0 ? row.VolumeLots.toFixed(2) : '-',
        row.PaymentUSD || row.PaymentUSD === 0 ? row.PaymentUSD.toFixed(2) : '-',
      ];
      rows.push(tableRow);
    });

    return rows;
  };

  const sendReportByEmail = useSendEmailByClient({
    toDate,
    fromDate,
    selected: selectedColumns,
    reportColumns: columnNames,
    partnershipCode,
  });

  return (
    <ReportDetailsDialog
      trigger={trigger}
      title={title}
      subTitle={subTitle}
      reportColumns={columnNames}
      selectedColumns={selectedColumns}
      exportTableData={renderTableData()}
      exportReportName={exportReportName()}
      handleGroupBy={setGroupBy}
      handleSelectColumns={handleSelectChange}
      handleCancel={handleCancel}
      sendEmail={sendReportByEmail}
      reportNameInEn="Commission By Client Details"
    >
      <DetailedTable translation={translation} groupByOption={groupBy} columnNames={columnNames} selectedColumns={selectedColumns} rows={data} />
    </ReportDetailsDialog>
  );
};
