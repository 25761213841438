import moment from 'moment';
import { enUS, ja, fr, de, nl, es, pt, ar, it, th, zhCN, ko, vi, id } from 'date-fns/locale';
import { ValueLabel } from '../models';

export function a11yProps(index: number | string): any {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export function dateObjectToMonthYearString(value: Date): string | null {
  if (value != null) return moment.utc(`${value.getUTCFullYear().toString()}-${(value.getMonth() + 1).toString()}-${value.getDate().toString()}`, 'YYYY-MM-DD').format('MMMM YYYY');

  return null;
}

export const errorInitFunction = (): string | undefined => {
  throw new Error('AppStateContext not initialized');
};

export const formatNumber = (v: number, currency: string): string => Number(v).toLocaleString('en-US', { style: 'currency', currency });

export function convertUTCDateToLocal(utcDateTime: string, format?: string): string {
  return moment.utc(utcDateTime).local().format(format);
}

export function convertLocalDateToUTC(localDateTime: string, format?: string): string {
  return moment.utc(localDateTime).format(format);
}

export function convertDate(Datetime: string): string {
  return moment(Datetime).format('DD/MM/YYYY');
}

export const getLocale = (language: string): Locale => {
  switch (language) {
    case 'en':
      return enUS;
    case 'ja':
      return ja;
    case 'fr':
      return fr;
    case 'de':
      return de;
    case 'nl':
      return nl;
    case 'es':
      return es;
    case 'pt':
      return pt;
    case 'ar':
      return ar;
    case 'it':
      return it;
    case 'zh':
      return zhCN;
    case 'th':
      return th;
    case 'ko':
      return ko;
    case 'vi':
      return vi;
    case 'id':
      return id;
    default:
      return enUS;
  }
};

export const getSelectedColumnsInEn = (enColumns: string[], selectedColumns: string[], columns: string[]): string[] => {
  const result: string[] = [];

  columns.forEach((column, index) => {
    if (selectedColumns.includes(column)) {
      result.push(enColumns[index]);
    }
  });

  return result;
};

export const getTranslatedColumnsInCorrectOrder = (selectedColumns: string[], columns: string[]): string[] => {
  const result: string[] = [];

  columns.forEach((column) => {
    if (selectedColumns.includes(column)) {
      result.push(column);
    }
  });

  return result;
};

export const getTranslation = (translation: Record<string, string>, value: string) =>
  translation[
    value
      .toLowerCase()
      .replaceAll(' ', '_')
      .replaceAll('__', '_')
      .replaceAll(/[^a-zA-Z0-9_]/g, '')
  ] || value;

export const getOptions = (list: any[], labelName: string, valueName: string): ValueLabel[] =>
  list.map((elem) => ({
    value: elem[valueName],
    label: elem[labelName],
  }));
