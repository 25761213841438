import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    tabPanel: {
      marginTop: 20,
      width: '100%',
    },
  }),
);
