import * as amplitude from '@amplitude/analytics-browser';

const trackingEnabled = process.env.REACT_APP_AMPLITUDE_TRACKING_ENABLED;

export const initAmplitude = () => {
  const apiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
  if (apiKey && trackingEnabled) {
    amplitude.init(apiKey, {
      autocapture: {
        attribution: false,
        pageViews: {
          trackHistoryChanges: 'all',
        },
        sessions: false,
        formInteractions: false,
        fileDownloads: false,
        elementInteractions: true,
      },
    });
  }
};

export const trackAmplitudeData = (eventType: string | amplitude.Types.BaseEvent, eventProperties?: Record<string, unknown>) => {
  if (trackingEnabled) {
    amplitude.track(eventType, eventProperties);
  }
};
