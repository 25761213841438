/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AppBar, Box, Button, Grid, Tab, TableCell, TableRow, Tabs } from '@material-ui/core';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useStyles } from '../styles';
import { useApiError, useLoading } from '../../../../providers';
import { CustomTable, TabPanel } from '../../../../components';
import { a11yProps, formatNumber, PATH_TRADING_SUMMARY } from '../../../../utils';
import { StackedLineChart, TitlePanel } from './components';
import { getDashboardInsights } from '../../IBDashboardService';
import { HighlightTypes } from '../../IBDashboardModels';
import { InsightPlatformList, InsightSymbolList, VolumeAndVolumeLots } from './VolumAndVolumeLotsModels';
import { MobileTable } from './components/MobileTable';
import { useCommonStyles } from '../../../../styles';

interface Props {
  partnership: string;
  currency: string;
  translation: any;
}

export const VolumeVolumeLotsPanel: FC<Props> = ({ partnership, currency, translation }) => {
  const columns = [translation.text_6378, translation.text_6379, translation.text_6380, translation.text_6381, translation.text_6382];
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const currentDate = useMemo(() => new Date(), []);
  const [fromDate, setFromDate] = useState<Date>(moment().startOf('year').toDate());
  const [toDate, setToDate] = useState<Date>(currentDate);
  const { addError } = useApiError();
  const { setIsLoading } = useLoading();
  const [value, setValue] = useState<number>(0);
  const [columnNames, setColumnNames] = useState<string[]>([columns[0], ...columns.slice(2, 5)]);
  const [symbolLots, setSymbolLots] = useState<number[]>([]);
  const [symbolVolumes, setSymbolVolumes] = useState<number[]>([]);
  const [platformLots, setPlatformLots] = useState<number[]>([]);
  const [platformVolumes, setPlatformVolumes] = useState<number[]>([]);
  const [volume, setVolume] = useState<number>(0);
  const [volumeLots, setVolumeLots] = useState<number>(0);
  const [increasedVolume, setIncreasedVolume] = useState<number | undefined>();
  const [increasedVolumeLots, setIncreasedVolumeLots] = useState<number | undefined>();
  const [decreasedVolume, setDecreasedVolume] = useState<number | undefined>();
  const [decreasedVolumeLots, setDecreasedVolumeLots] = useState<number | undefined>();
  const [symbolTableData, setSymbolTableData] = useState<InsightSymbolList[]>([]);
  const [platformTableData, setPlatformTableData] = useState<InsightPlatformList[]>([]);

  const getInsightsData = useCallback(
    async (partnershipCode: string, from: Date, to: Date) => {
      setIsLoading(true);
      try {
        const response = await getDashboardInsights(from, to, partnershipCode === 'All' ? '' : partnershipCode, HighlightTypes.Volume);
        const insightData: VolumeAndVolumeLots = response.data.partnerInsightViewModel;
        setVolume(insightData.volumeSum);
        setVolumeLots(insightData.volumeLotsSum);
        if (insightData.volumeIncrease && insightData.volumeIncrease < 0) {
          setDecreasedVolume(Math.abs(insightData.volumeIncrease));
        } else {
          setIncreasedVolume(insightData.volumeIncrease);
        }
        if (insightData.volumeLotsIncrease && insightData.volumeLotsIncrease < 0) {
          setDecreasedVolumeLots(insightData.volumeLotsIncrease);
        } else {
          setIncreasedVolumeLots(insightData.volumeLotsIncrease);
        }
        setSymbolTableData(insightData.partnerDashboardVolumeAndVolumeLotsInsightSymbolList);
        setPlatformTableData(insightData.partnerDashboardVolumeAndVolumeLotsInsightPlatformList);

        const symbolLotsData: number[] = [];
        const symbolVolumeData: number[] = [];

        insightData.partnerDashboardVolumeAndVolumeLotsInsightSymbolList.forEach((item, index) => {
          if (index >= 5) {
            return;
          }
          symbolLotsData.push(item.lots);
          symbolVolumeData.push(item.volume);
        });

        setSymbolLots(symbolLotsData);
        setSymbolVolumes(symbolVolumeData);

        const platformLotsData: number[] = [];
        const platformVolumeData: number[] = [];

        insightData.partnerDashboardVolumeAndVolumeLotsInsightPlatformList.forEach((item) => {
          platformLotsData.push(item.lots);
          platformVolumeData.push(item.volume);
        });

        setPlatformLots(platformLotsData);
        setPlatformVolumes(platformVolumeData);
      } catch (error) {
        addError(JSON.stringify(error.response));
      } finally {
        setIsLoading(false);
      }
    },
    [addError],
  );

  useEffect(() => {
    if (fromDate <= toDate) {
      (async () => {
        await getInsightsData(partnership, fromDate, toDate);
      })();
    }
  }, [partnership, fromDate, toDate, currency]);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);

    if (newValue === 1) {
      setColumnNames([columns[1], ...columns.slice(2, 5)]);
    } else {
      setColumnNames([columns[0], ...columns.slice(2, 5)]);
    }
  };

  const renderHeader = useMemo((): any[] => {
    const result: any[] = [];
    columnNames.forEach((columnName) => {
      result.push(
        <TableCell key={columnName} align={columnName === columns[0] || columnName === columns[1] ? 'left' : 'center'}>
          {columnName}
        </TableCell>,
      );
    });
    return result;
  }, [columnNames]);

  const renderRows = useCallback(
    (data: any): any[] => {
      const result: any[] = [];

      data.forEach((row: any, index: number) => {
        result.push(
          <TableRow key={columnNames.includes(columns[0]) ? row.symbol : row.platform} className={classes.volumeTableRow}>
            <TableCell align="left">
              <Box
                component="div"
                className={`${classes.volumeTableItem} 
            ${index === 0 ? classes.volumeTableItem0 : index === 1 ? classes.volumeTableItem1 : index === 2 ? classes.volumeTableItem2 : index === 3 ? classes.volumeTableItem3 : classes.volumeTableItem4}`}
              >
                {columnNames.includes(columns[0]) ? row.symbol : row.platform}
              </Box>
            </TableCell>
            <TableCell align="center">{row.lots.toFixed(2).toLocaleString()}</TableCell>
            <TableCell align="center">{formatNumber(row.volume, currency)}</TableCell>
            <TableCell align="center">{formatNumber(row.commisions, currency)}</TableCell>
          </TableRow>,
        );
      });

      return result;
    },
    [columnNames, classes],
  );

  return (
    <>
      <TitlePanel
        subTitle={translation.text_6329}
        partnership={partnership}
        volume={volume.toFixed(2)}
        volumeLots={volumeLots.toFixed(2)}
        increased={increasedVolume}
        decreased={decreasedVolume}
        increasedLots={increasedVolumeLots}
        decreasedLots={decreasedVolumeLots}
        fromDate={fromDate}
        toDate={toDate}
        translation={translation}
        handleFromDateChange={setFromDate}
        handleToDateChange={setToDate}
      />
      <Grid container direction="column" alignContent="center" className={classes.content}>
        {symbolTableData.length > 0 && platformTableData.length > 0 ? (
          <>
            <AppBar position="static" className={classes.tabsBar}>
              <Tabs value={value} onChange={handleChange}>
                <Tab label={translation.text_6376} {...a11yProps(0)} />
                <Tab label={translation.text_6377} {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <Grid className={classes.table}>
                <CustomTable headerCells={renderHeader} rows={renderRows(symbolTableData)} columnsNumber={columnNames.length} headerClassName={classes.tableHeader} hidePagination />
              </Grid>
              <Grid className={classes.mobileTable}>
                <MobileTable rows={symbolTableData} columnNames={columnNames} columns={columns} currency={currency} />
              </Grid>
              <Grid item className={classes.graphTitle} style={{ marginTop: 42 }}>
                {translation.text_6383}
              </Grid>
              <StackedLineChart data={symbolLots} key="lots" />
              <Grid item className={classes.graphTitle} style={{ marginTop: 32 }}>
                {translation.text_6384}
              </Grid>
              <StackedLineChart data={symbolVolumes} key="volume" />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid className={classes.table}>
                <CustomTable headerCells={renderHeader} rows={renderRows(platformTableData)} columnsNumber={columnNames.length} headerClassName={classes.tableHeader} hidePagination />
              </Grid>
              <Grid className={classes.mobileTable}>
                <MobileTable rows={platformTableData} columnNames={columnNames} columns={columns} currency={currency} />
              </Grid>
              <Grid item className={classes.graphTitle}>
                {translation.text_6383}
              </Grid>
              <StackedLineChart data={platformLots} />
              <Grid item className={classes.graphTitle} style={{ marginTop: 32 }}>
                {translation.text_6384}
              </Grid>
              <StackedLineChart data={platformVolumes} />
            </TabPanel>
          </>
        ) : (
          <Grid container direction="column" alignContent="center" alignItems="center" className={classes.emptyMessage}>
            <Grid item xs={12}>
              {translation.text_6385}
            </Grid>
          </Grid>
        )}
        <Grid container className={classes.buttons}>
          <Grid container direction="row" className={`${commonClasses.linkButtons} ${classes.button}`}>
            <Grid item>
              <Button variant="contained" color="primary" component={Link} to={PATH_TRADING_SUMMARY} className={`${commonClasses.linkButtons} ${classes.button}`}>
                {translation.text_6360}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
