/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import { getUserLoginData } from './LoginService';
import { useAppData, useLoading } from '../../providers';
import { clearThemeCache } from '../../layouts';
import { PATH_WELCOME, getTranslation } from '../../utils';
import { trackAmplitudeData } from '../../services/amplitudeService';

export const LoginAsPartner: FC = () => {
  const { pagePath, saveUserData, translations, saveTranslations, saveToken, removeToken } = useAppData();
  const history = useHistory();
  const { setIsLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const { search } = useLocation();
  const Token = new URLSearchParams(search).get('Token')?.toString();

  const setPath = useCallback(
    (userLoginData: any) => {
      const routesArray: string[] = [];
      let defaultRoute = '';

      if (userLoginData.accounts != null && userLoginData.accounts.length > 0) {
        // All accounts
        const accountCollection = userLoginData.accounts;

        // Loop each account
        accountCollection.forEach((account: any) => {
          const { routeCollection } = account.role;
          defaultRoute = routeCollection.defaultRoute.route;
          const routeCollectionArray = routeCollection.routeCollectionHasRoute;

          routeCollectionArray.forEach((routeIndex: any) => {
            routesArray.push(routeIndex.route);
          });
        });
      }
      let path = defaultRoute;
      if (pagePath) {
        const exist = routesArray.filter((route) => route === pagePath || route.indexOf(pagePath) > -1).length > 0;
        path = exist ? pagePath : defaultRoute;
      }
      history.push(path);
    },
    [history, pagePath],
  );

  const getLoginData = useCallback(async () => {
    try {
      const response = await getUserLoginData();
      const userData = response.data;
      if (userData.accounts[0].userRoleName !== 'Partner' && userData.accounts[0].role.id !== 7) {
        removeToken();
        history.push(PATH_WELCOME);
      } else {
        saveUserData(JSON.stringify(userData));
        setPath(userData);
      }
    } catch (error) {
      const errorData = error.response.status ? error.response.status : error;
      enqueueSnackbar(getTranslation(JSON.parse(translations), errorData), {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, saveUserData, setPath, removeToken]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        saveToken(Token);

        const eventProperties = {
          accountType: 'Partner',
        };
        trackAmplitudeData('partnerPortal_login', eventProperties);

        await clearThemeCache();
        await getLoginData();
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        enqueueSnackbar(JSON.parse(translations).authorisation_failed, {
          variant: 'error',
        });
        history.push(PATH_WELCOME);
      }
    })();
  }, [getLoginData, saveTranslations]);
  return <div />;
};
