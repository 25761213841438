import React, { FC } from 'react';
import { ListItem, Button, SvgIconTypeMap } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { useStyles } from '../styles';

interface MainMenuItemProps {
  name: string;
  Icon?: OverridableComponent<SvgIconTypeMap>;
  link?: string;
}

export const SubMenuItem: FC<MainMenuItemProps> = ({ name, Icon, link }) => {
  const classes = useStyles();
  const location = useLocation();

  // const handleClick = useCallback(
  //   (menuItem: string) => {
  //     if (selectedItem === menuItem) {
  //       handleSelectedItem('');
  //     } else {
  //       handleSelectedItem(menuItem);
  //     }
  //   },
  //   [selectedItem, handleSelectedItem],
  // );

  return (
    <ListItem key={name} component={Link} to={link || ''} className={classes.subListItem}>
      <Button className={`${classes.subMenuButton} ${location.pathname === link ? classes.subMenuButtonSelected : ''}`} startIcon={Icon ? <Icon /> : null}>
        {name}
      </Button>
    </ListItem>
  );
};
