/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { AppBar, FormControl, Grid, Tabs, Tab, MenuItem, Select } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import useStyles from './styles';
import { PartnerPartnership } from '../../models';
import { ClientLinkSetup, Link, MlmLinkSetup, SubPartnershipLinkSetup } from './PartnershipLinkDialogModel';
import { getClientLinkSetup, getClientLinks, getSubPartnershipLinkSetup, getSubPartnershipLinks, getMLMLinkSetup } from '../../pages';
import { Dialog } from '../Dialog';
import { CreateClientLinkTab } from './CreateClientLinkTab';
import { ExistingLinksTab } from './ExistingLinksTab';
import { CreatePartnershipLinkTab } from './CreatePartnershipLinkTab';
import { FieldLabel } from '../Form';
import { useApiError, useAppData, useLoading } from '../../providers';
import { CreateMLMLinkTab } from './CreateMLMLinkTab';
import { a11yProps } from '../../utils';
import { TabPanel } from '../TabPanel';

interface PartnershipLinkDialogProps {
  partnerships?: PartnerPartnership[];
  trigger?: number;
  handleClose: () => void;
}

export const PartnershipLinkDialog: FC<PartnershipLinkDialogProps> = memo(({ partnerships, trigger, handleClose }) => {
  const classes = useStyles();
  const { addError } = useApiError();
  const { translations } = useAppData();
  const { setIsLoading } = useLoading();
  const [activeTab, setActiveTab] = useState<number>(4);
  const [clientLinks, setClientLinks] = useState<Link[]>([]);
  const [subPartnershipLinks, setSubPartnershipLinks] = useState<Link[]>([]);
  const [mlmLinks, setMlmLinks] = useState<Link[]>([]);
  const [clientLinkSetup, setClientLinkSetup] = useState<ClientLinkSetup>();
  const [subPartnershipLinkSetup, setSubPartnershipLinkSetup] = useState<SubPartnershipLinkSetup>();
  const [mlmLinkSetup, setMlmLinkSetup] = useState<MlmLinkSetup>();
  const [selectedPartnership, setSelectedPartnership] = useState<PartnerPartnership | null>(partnerships && partnerships.length > 0 ? partnerships[0] : null);
  const createClientLinkTabFormRef = useRef<any>();
  const subIBLinksTabFormRef = useRef<any>();
  const [translation, setTranslation] = useState<any>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [canCreateDefaultLink, setCanCreateDefaultLink] = useState<boolean>(false);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  const dialogActions = () => {
    if (activeTab !== 4) {
      return [
        {
          key: '1',
          title: translation.text_6665,
          onClick: () => handleClose(),
          isPrimary: false,
        },
      ];
    }
    return [
      {
        key: '1',
        title: translation.text_6665,
        onClick: () => handleClose(),
        isPrimary: false,
      },
    ];
  };

  const getData = useCallback(
    async (id: number) => {
      setIsLoading(true);
      try {
        if (selectedPartnership) {
          if (selectedPartnership.partnershipType?.name === 'IB' || selectedPartnership.partnershipType?.name === 'MLM') {
            const { links, linkSetup } = await Promise.all([getClientLinks(id), getClientLinkSetup(id)]).then(([{ data: links }, { data: linkSetup }]) => ({
              links,
              linkSetup,
            }));

            setClientLinks(links.links);
            setClientLinkSetup(linkSetup);
            setCanCreateDefaultLink(linkSetup.canCreateDefaultLink);

            if (selectedPartnership.partnershipType?.name === 'MLM') {
              const { links, linkSetup } = await Promise.all([getSubPartnershipLinks(id), getMLMLinkSetup(id)]).then(([{ data: links }, { data: linkSetup }]) => ({ links, linkSetup }));

              setMlmLinks(links.links);
              setMlmLinkSetup(linkSetup);
            }
          } else {
            const { links, linkSetup } = await Promise.all([getSubPartnershipLinks(id), getSubPartnershipLinkSetup(id)]).then(([{ data: links }, { data: linkSetup }]) => ({
              links,
              linkSetup,
            }));

            setSubPartnershipLinks(links.links);
            setSubPartnershipLinkSetup(linkSetup);
          }
        }
      } catch (error) {
        addError(JSON.stringify(error.response));
        if (error.response.data.code === 'MlmLinks.NotAllowed') {
          setIsError(true);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [addError, selectedPartnership],
  );

  useEffect(() => {
    if (partnerships && partnerships.length > 0) {
      if (selectedPartnership === null) {
        setSelectedPartnership(partnerships[0]);
      }
    }
  }, [partnerships, selectedPartnership]);

  useEffect(() => {
    const partnershipId = selectedPartnership?.id;

    if (partnershipId && !isError) {
      (async () => {
        await getData(partnershipId);
      })();
    }
  }, [selectedPartnership]);

  const handleTabChange = useCallback((event: any, newValue: number) => {
    setActiveTab(newValue);
  }, []);

  const handlePartnershipChange = useCallback(
    (event: ChangeEvent<{ value: any }>) => {
      const partnership = partnerships?.find((p) => p.id === Number(event.target.value));

      if (partnership) {
        setSelectedPartnership(partnership);
        setActiveTab(4);
        setIsError(false);
        setCanCreateDefaultLink(false);

        setMlmLinks([]);
        setMlmLinkSetup(undefined);
        setClientLinks([]);
        setClientLinkSetup(undefined);
        setSubPartnershipLinks([]);
        setSubPartnershipLinkSetup(undefined);
      }
    },
    [partnerships],
  );

  const getLinks = useCallback(
    async (id: number) => {
      if (selectedPartnership) {
        if (selectedPartnership.partnershipType?.name === 'IB' || selectedPartnership.partnershipType?.name === 'MLM') {
          const links = await getClientLinks(id);
          setClientLinks(links.data.links);

          if (selectedPartnership.partnershipType?.name === 'MLM') {
            const links = await getSubPartnershipLinks(id);
            setMlmLinks(links.data.links);
          }
        } else {
          const links = await getSubPartnershipLinks(id);
          setSubPartnershipLinks(links.data.links);
        }
      }
    },
    [selectedPartnership],
  );

  return (
    <Dialog trigger={trigger} title={translation.text_6235} onClose={() => handleClose()} actions={dialogActions()} classClose={classes.closeBtn} className={classes.dialogWidth}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FieldLabel label={translation.text_6242} isRequired />
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              displayEmpty
              value={selectedPartnership ? selectedPartnership.id : ''}
              onChange={handlePartnershipChange}
              inputProps={{
                IconComponent: ExpandMore,
              }}
              classes={{
                select: classes.partnershipSelect,
              }}
            >
              {partnerships?.map((partnership) => (
                <MenuItem key={partnership.partnershipCode} value={partnership.id}>
                  {partnership.partnershipCode}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {selectedPartnership !== null && selectedPartnership?.id && (
          <>
            <AppBar position="static" className={classes.tabs}>
              <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
                {selectedPartnership.partnershipType && (selectedPartnership.partnershipType.name === 'IB' || selectedPartnership.partnershipType.name === 'MLM') ? (
                  <Tab className={classes.tab} label={translation.text_373} value={1} {...a11yProps(1)} />
                ) : (
                  <Tab className={classes.tab} label={translation.new_Sub_Partnership_Link} value={2} {...a11yProps(2)} />
                )}
                {selectedPartnership.partnershipType?.name === 'MLM' && <Tab className={classes.tab} label={translation.new_MLM_Link} value={3} {...a11yProps(3)} />}
                <Tab className={classes.tab} label={translation.text_377} value={4} {...a11yProps(4)} />
              </Tabs>
            </AppBar>
            <TabPanel value={activeTab} index={1}>
              <CreateClientLinkTab
                formRef={createClientLinkTabFormRef}
                partnershipId={selectedPartnership.id as number}
                tradingAccountTypes={clientLinkSetup?.tradingAccountTypes || []}
                translation={translation}
                brokers={clientLinkSetup?.brokers ?? []}
                countries={clientLinkSetup?.countries ?? []}
                masterCodes={clientLinkSetup?.masterCodeConfigurations ?? []}
                setLoading={setIsLoading}
                onLinkGeneration={getLinks}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <CreatePartnershipLinkTab
                translation={translation}
                formRef={subIBLinksTabFormRef}
                partnershipId={selectedPartnership.id as number}
                brokers={subPartnershipLinkSetup?.brokers ?? []}
                linkConfigurations={subPartnershipLinkSetup?.linkConfigurations ?? []}
                setLoading={setIsLoading}
                onLinkGeneration={getLinks}
              />
            </TabPanel>
            {mlmLinkSetup && (
              <TabPanel value={activeTab} index={3}>
                <CreateMLMLinkTab
                  formRef={subIBLinksTabFormRef}
                  partnershipId={selectedPartnership.id as number}
                  broker={mlmLinkSetup.broker}
                  linkConfigurations={mlmLinkSetup?.linkConfigurations ?? []}
                  setLoading={setIsLoading}
                  onLinkGeneration={getLinks}
                />
              </TabPanel>
            )}
            <TabPanel value={activeTab} index={4}>
              <ExistingLinksTab partnershipId={selectedPartnership?.id} setLoading={setIsLoading} canCreateDefaultLink={canCreateDefaultLink} clientLinks={clientLinks} subPartnershipLinks={subPartnershipLinks} mlmLinks={mlmLinks} />
            </TabPanel>
          </>
        )}
      </Grid>
    </Dialog>
  );
});
