import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  languageSelectControl: {
    '& svg': {
      top: 'auto',
    },
  },
  languageItem: {
    display: 'flex',
    color: theme.palette.text.primary,
    alignContent: 'center',
    // textTransform: 'capitalize'
  },
  menu: {
    marginTop: 30,
    marginRight: 6,
  },
  languageSelect: {
    marginTop: 105,
  },
  languageSelectedItem: {
    height: 14,
    marginRight: 5,
  },
  languageSelectItem: {
    height: 18,
    marginRight: 5,
  },
  userName: {
    color: theme.palette.primary.light,
    fontWeight: 500,
    fontSize: 15,
  },
  userRole: {
    color: theme.palette.primary.light,
    fontWeight: 400,
    fontSize: 15,
  },
  headerListItem: {
    width: 'fit-content',
  },
  headerListItemButton: {
    color: theme.palette.primary.main,
    padding: 0,
    fontWeight: 500,
    textAlign: 'right',
    minWidth: 'fit-content',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    '& .MuiButton-label': {
      justifyContent: 'right',
    },
  },
}));
