import { useCallback } from 'react';
import { ReportMessageContent, ReportModel } from 'src/models';
import { useApiError, useAppData } from 'src/providers';
import { getReports } from '../CommissionsByClientService';
import { trackAmplitudeData } from '../../../../services/amplitudeService';

const useGetReports = ({
  toDate,
  fromDate,
  setReports,
  showReports,
  setIsLoading,
  setShowReports,
}: {
  toDate: Date;
  fromDate: Date;
  showReports: boolean;
  setIsLoading: (data: boolean) => void;
  setShowReports: (data: boolean) => void;
  setReports: (data: ReportModel[]) => void;
}) => {
  const { addError } = useApiError();
  const { userData } = useAppData();

  return useCallback(async () => {
    const loginData = JSON.parse(userData);
    const messageContent: ReportMessageContent = {
      PartnerId: loginData.partnerID,
      Month: fromDate.getMonth() + 1,
      Year: fromDate.getFullYear(),
      Day: fromDate.getDate(),
      EndMonth: toDate.getMonth() + 1,
      EndYear: toDate.getFullYear(),
      EndDay: toDate.getDate(),
      ConvertToCurrency: 'USD',
      RequestorAccountGuid: loginData.defaultAccount,
    };

    setIsLoading(true);

    try {
      trackAmplitudeData('partnerPortal_action_report', {
        type: 'Requested',
        report_name: 'Commission By Client',
      });

      const response = await getReports(messageContent);

      setReports(response.data);

      if (!showReports) {
        setShowReports(true);
      }
    } catch (error) {
      setReports([]);
      addError(JSON.stringify(error.response));
    } finally {
      setIsLoading(false);
    }
  }, [userData, fromDate, toDate, setIsLoading, setReports, showReports, setShowReports, addError]);
};

export default useGetReports;
