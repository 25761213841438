import moment from 'moment';
import { instanceApi } from '../../services';
import { GeneratePartnerChildLinkRequestModel, GeneratePartnerLinkRequestModel, GeneratePartnerMasterLink, HighlightTypes, Platforms } from './IBDashboardModels';

export const getCurrencies = (): Promise<any> => instanceApi.get('api/PartnersManagement/GetCurrencies');

export const getGeneralSettings = (): Promise<any> => instanceApi.post('api/settings/getgeneralsettings');

export const getPartnerSettings = (key: string): Promise<any> => instanceApi.get(`api/PartnersManagement/GetPartnerSetting?key=${key}`);

export const getPartnerPartnerships = (showExpired: boolean): Promise<any> => instanceApi.get(`api/Dashboard/GetPartnerPartnerships?showExpired=${showExpired}`);

export const setPartnerSettings = (key: string, value: string): Promise<any> => instanceApi.post('api/PartnersManagement/SetPartnerSetting', { key, value });

export const getAggregatedValues = (partnershipCode: string, period: number, platform: Platforms): Promise<any> =>
  instanceApi.get(`api/Dashboard/GetAggregatedValues?partnerUniversalCode=${partnershipCode}&period=${period}&platform=${platform}&accountGuidAuth=`);

export const generatePartnerChildLink = (data: GeneratePartnerChildLinkRequestModel): Promise<any> => instanceApi.post('api/ClientPortal/GeneratePartnerChildLink', data);

export const getHighlightData = (fromDate: Date, toDate: Date, partnershipCode: string, highlightType: number): Promise<any> =>
  instanceApi.get(`api/Dashboard/getHighlightData?FromDate=${moment(fromDate).format('YYYY-MM-DD')}&ToDate=${moment(toDate).format('YYYY-MM-DD')}&PartnershipCode=${partnershipCode}&HighlightType=${highlightType}`);

export const getDashboardInsights = (fromDate: Date, toDate: Date, partnershipCode: string, highlightType: HighlightTypes): Promise<any> =>
  instanceApi.get(`api/Dashboard/GetPartnerSelectedInsightData?FromDate=${moment(fromDate).format('YYYY-MM-DD')}&ToDate=${moment(toDate).format('YYYY-MM-DD')}&PartnershipCode=${partnershipCode}&HighlightType=${highlightType}`);

export const generatePartnershipLink = (data: GeneratePartnerMasterLink, partnershipId: number): Promise<any> => instanceApi.post(`api/partners/partnership/links/${partnershipId}/partnership`, data);

export const generateClientLink = (data: GeneratePartnerLinkRequestModel, partnershipId: number): Promise<any> => instanceApi.post(`api/partners/partnership/links/${partnershipId}/client`, data);

export const getClientLinkSetup = (partnershipId: number): Promise<any> => instanceApi.get(`api/partners/partnership/links/${partnershipId}/client/setup`);

export const getSubPartnershipLinkSetup = (partnershipId: number): Promise<any> => instanceApi.get(`api/partners/partnership/links/${partnershipId}/partnership/setup`);

export const getMLMLinkSetup = (partnershipId: number): Promise<any> => instanceApi.get(`api/partners/partnership/links/${partnershipId}/mlm/setup`);

export const getClientLinks = (partnershipId: number): Promise<any> => instanceApi.get(`api/partners/partnership/links/${partnershipId}/client`);

export const getSubPartnershipLinks = (partnershipId: number): Promise<any> => instanceApi.get(`api/partners/partnership/links/${partnershipId}/partnership`);

export const getSchemeByPartnershipId = (partnershipId: number | string): Promise<any> => instanceApi.get(`api/PartnersManagement/GetSchemeByPartnershipId?partnershipId=${partnershipId}`);

export const getBrokers = async (): Promise<any> => instanceApi.get('api/brokersmanagement/getbrokers');

export const getPropertiesForClientSetupURL = (url: string): Promise<any> => instanceApi.get(`api/partners/partnership/links/client/details?url=${encodeURIComponent(url)}`);

export const getPropertiesForSubPartnershipSetupURL = (shortCode: string): Promise<any> => instanceApi.get(`api/partners/partnership/links/details?shortCode=${shortCode}`);

export const createDefaultLink = (partnershipId: number): Promise<any> => instanceApi.post(`api/partners/partnership/links/${partnershipId}/client/default`);
