import React, { FC, useEffect, useState } from 'react';
import { Button, FormControl, Grid, IconButton, MenuItem, Select, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useStyles } from './styles';
import { MainMenu } from '../MainMenu';
import { QuickLinks } from '../QuickLinks';
import { AngleDownIcon } from '../CustomIcons';
import { useAppData } from '../../providers';
import { getTranslation } from '../../utils';

interface Props {
  selectedLocale: any;
  languages: any[];
  translation: any;
  handleLogout: () => void;
  handleLocaleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleCloseMenu: () => void;
}

export const MobileMenuPanel: FC<Props> = ({ handleCloseMenu, selectedLocale, languages, translation, handleLogout, handleLocaleChange }) => {
  const classes = useStyles();
  const history = useHistory();
  const { userData } = useAppData();
  const [userName, setUserName] = useState<string>('');
  const [userRole, setUserRole] = useState<string>('');

  useEffect(() => {
    const user = JSON.parse(userData);
    setUserName(user.userDisplayName);
    setUserRole(getTranslation(translation, user.accounts[0].userRoleName));
  }, [translation, userData]);

  useEffect(
    () =>
      history.listen(() => {
        handleCloseMenu();
      }),
    [history, handleCloseMenu],
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 1180) {
        handleCloseMenu();
      }
    });
  });

  return (
    <Grid container direction="column" alignItems="center" className={classes.root}>
      <Grid container direction="row-reverse">
        <IconButton onClick={handleCloseMenu}>
          <Close fontSize="large" />
        </IconButton>
      </Grid>
      <Grid container direction="row">
        <Typography variant="h5">{translation.menu}</Typography>
      </Grid>
      <Grid container direction="column" alignItems="flex-start">
        <Grid item className={classes.headerListItem}>
          <span className={classes.userName}>
            {translation.hi}, {userName}!
          </span>
        </Grid>
        <Grid item className={classes.headerListItem}>
          <span className={classes.userRole}>
            {translation.logged_in_as}: {userRole}
          </span>
        </Grid>
        <Grid item className={classes.headerListItem}>
          <Button className={classes.headerListItemButton} onClick={handleLogout}>
            {translation.text_6040}
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="column" className={classes.menu}>
        <MainMenu />
        <QuickLinks />
        <Grid item className={classes.languageSelect}>
          <FormControl variant="standard" className={classes.languageSelectControl}>
            <Select
              onChange={handleLocaleChange}
              renderValue={(value: any) => (
                <div className={classes.languageItem}>
                  <span className={classes.languageSelectedItem}>{value.icon}</span>
                  {value.label}
                </div>
              )}
              autoWidth
              inputProps={{
                IconComponent: AngleDownIcon,
              }}
              value={selectedLocale}
              disableUnderline
            >
              {languages.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <span className={classes.languageSelectItem}>{item.icon}</span>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};
