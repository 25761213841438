import React, {
  createContext, FC, useCallback, useEffect, useState,
} from 'react';
import { fromEventPattern, Observable, of } from 'rxjs';
import { HubConnection } from '@microsoft/signalr';
import { share, switchMap, take } from 'rxjs/operators';
import { useAppData } from './useAppData';
import {
  errorInitFunction,
  errorInitOnFunction,
  getOrSetupConnection,
  InvokeFunction,
  OnFunction,
  SendFunction,
  UseSignalrHookResult
} from '../../services';

export const SignalRContext = createContext<UseSignalrHookResult>({
  invoke: errorInitFunction,
  send: errorInitFunction,
  on: errorInitOnFunction
});

export const SignalRProvider: FC = ({ children }) => {
  const hubUrl = `${process.env.REACT_APP_SIGNALER_ENDPOINT}`;

  const { authToken } = useAppData();
  const [connection$, setConnection] = useState<Observable<HubConnection>>();

  useEffect(() => {
    if (authToken) {
      setConnection(getOrSetupConnection(hubUrl,
        { withCredentials: true, accessTokenFactory: () => authToken }));
    }
  }, [authToken, hubUrl]);

  useEffect(() => {
    // used to maintain 1 active subscription while the hook is rendered
    if (connection$) {
      const subscription = connection$.subscribe();

      return () => subscription.unsubscribe();
    }
    return () => { };
  }, [connection$]);

  const send = useCallback<SendFunction>(
    (methodName: string, arg?: unknown) => {
      if (connection$) {
        return connection$
          .pipe(
            // only take the current value of the observable
            take(1),
            // use the connection
            switchMap((connection) => connection.send(methodName, arg))
          )
          .toPromise();
      }
      return new Promise<void>(() => {});
    },
    [connection$]
  );

  const invoke = useCallback<InvokeFunction>(
    (methodName: string, arg?: unknown) => {
      if (connection$) {
        return connection$
          .pipe(
            // only take the current value of the observable
            take(1),
            // use the connection
            switchMap((connection) => connection.invoke(methodName, arg))
          )
          .toPromise();
      }
      return new Promise<any>(() => {});
    },
    [connection$]
  );

  const on = useCallback<OnFunction>((methodName: string) => {
    if (connection$) {
      return connection$
        .pipe(
          // only take the current value of the observable
          take(1),
          // use the connection
          switchMap((connection) => fromEventPattern<any>(
            (handler: (...args: unknown[]) => void) => connection.on(methodName, handler),
            (handler: (...args: unknown[]) => void) => connection.off(methodName, handler)
          ))
        )
        .pipe(share());
    }
    return of<any>(null);
  },
  [connection$]);

  return (
    <SignalRContext.Provider value={{ invoke, send, on }}>
      {children}
    </SignalRContext.Provider>
  );
};
