import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  LogoContainer: {
    borderRadius: 10,
    margin: '0px 80px 0px 33px',
    width: 'fit-content',
    height: 115,
    display: 'flex',
    alignItems: 'center',
    padding: '35px 20px',

    '@media (max-width: 1180px)': {
      height: 'auto',
      margin: 0,
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  imageContent: {
    '@media (max-width: 1024px)': {
      width: 110,
    },
  },
  logoImg: {
    width: 200,
    '@media (max-width: 1024px)': {
      width: 84,
    },
  },
  LogoTitle: {
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: 400,
    textTransform: 'uppercase',
    marginLeft: 20,

    '@media (max-width: 1180px)': {
      fontSize: 12,
      margin: 'unset',
    },
  },
}));
