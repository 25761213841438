import React, { FC, ReactNode } from 'react';
import { useStyles } from './styles';

interface TabPanelProps {
  children?: ReactNode;
  index: any;
  value: any;
}

export const TabPanel: FC<TabPanelProps> = ({ value, index, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.tabPanel} role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
      {value === index && <>{children}</>}
    </div>
  );
};
