import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Card, CardActions, CardContent, Container, FormControl, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useStyles } from './LoginStyles';
import { AngleDownIcon } from '../../components';
import { getTranslationsByBrokerId } from './LoginService';
import { useAppData } from '../../providers';
import { extractLocaleFromPath, getURLParam, languages, supportedLocales } from '../../utils';

export const ErrorPage: FC = () => {
  const classes = useStyles();
  const { saveTranslations } = useAppData();
  const [translation, setTranslation] = useState<any>([]);
  const [selectedLocale, setSelectedLocale] = useState<any>(languages[0]);
  const location = useLocation();
  const lang = extractLocaleFromPath(location.pathname);

  const brokerId = getURLParam('BrokerId');

  const getTranslationList = useCallback(
    async (broker: string) => {
      const translations = await getTranslationsByBrokerId(broker);
      saveTranslations(JSON.stringify(translations.data));
      setTranslation(translations.data);
    },
    [saveTranslations],
  );

  const handleLocaleChange = useCallback(
    async (event: React.ChangeEvent<{ value: unknown }>) => {
      const selectedLanguage = languages.find((item) => item.value === (event.target.value as string)) || languages[0];
      setSelectedLocale(selectedLanguage);
      await getTranslationList(brokerId);
    },
    [getTranslationList, brokerId],
  );

  useEffect(() => {
    if (brokerId) {
      (async () => {
        if (lang && supportedLocales.includes(lang)) {
          const selectedLanguage = languages.find((item) => item.value === lang) || languages[0];
          setSelectedLocale(selectedLanguage);
          await getTranslationList(brokerId);
        } else {
          await getTranslationList(brokerId);
        }
      })();
    }
  }, [getTranslationList, brokerId, lang]);

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container direction="row-reverse">
        <FormControl variant="standard" className={classes.languageSelect}>
          <Select
            onChange={handleLocaleChange}
            renderValue={(value: any) => (
              <div className={classes.languageItem}>
                <span className={classes.languageSelectedItem}>{value.icon}</span>
                {value.value}
              </div>
            )}
            autoWidth
            inputProps={{
              IconComponent: AngleDownIcon,
            }}
            value={selectedLocale}
            disableUnderline
          >
            {languages.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                <span className={classes.languageSelectItem}>{item.icon}</span>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Container className={classes.container} maxWidth="xs">
        <div className={classes.errorCard}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h5">{translation.text_1582}</Typography>
              <Typography variant="body1">{translation.text_1583}</Typography>
            </CardContent>
            <CardActions className={classes.errorCardActions}>
              <Button fullWidth variant="contained" color="primary" href={`${process.env.REACT_APP_URL_REGISTER_LINK}?flowkey=ibrequest`}>
                Register
              </Button>
            </CardActions>
          </Card>
        </div>
      </Container>
    </Grid>
  );
};
