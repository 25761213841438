import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    mobileButtons: {
      display: 'none',
      width: 'calc(100% + 4px)',

      '@media (max-width: 1023px)': {
        display: 'flex',
        marginBottom: 22,
      },
    },
    printButton: {
      marginLeft: 26,
    },
    title: {
      color: theme.palette.text.primary,
      textAlign: 'left',
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: '1.334',
      letterSpacing: '0em',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    paper: {
      marginTop: 10,
      padding: 15,
      minHeight: '100vh',
      width: 'inherit',
    },
    reportStatusDialog: {
      color: theme.palette.common.white,
      padding: '5px 20px 5px 20px',
      borderRadius: 20,
      fontSize: 15,
    },
    completedStatus: {
      backgroundColor: theme.palette.primary.main,
    },
    failedStatus: {
      backgroundColor: theme.palette.error.main,
    },
    pendingStatus: {
      backgroundColor: theme.palette.frequent.pastelPink,
    },
    tableTotals: {
      backgroundColor: theme.palette.changeable.tableHeaderBgColor,
      borderRadius: 5,

      '& .MuiTableCell-root': {
        fontWeight: 'bold',
        padding: 16,
      },
      '& td:first-child': {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
      },

      '& td:last-child': {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
      },
    },
    arrowIcon: {
      color: theme.palette.frequent.cadetBlue,
    },
    tableRow: {
      '&:hover': {
        backgroundColor: theme.palette.frequent.ghostWhite,
      },
    },
    groupedByCell: {
      fontWeight: 'bold',
    },
    emptyReportMessageContainer: {
      marginTop: 140,

      '@media (max-width: 413px)': {
        marginTop: 98.5,
      },
    },
    button: {
      borderRadius: 25,
      padding: '10px 20px 10px 20px',
    },
    reportTable: {
      marginTop: '18px',
      '@media (max-width: 1023px)': {
        display: 'none',
      },
    },
    reportTableMobile: {
      marginTop: '18px',
      '@media (min-width: 1024px)': {
        display: 'none',
      },
    },
    subTitle: {
      textAlign: 'left',
      marginLeft: 43,
      marginBottom: 16,

      '@media (max-width: 600px)': {
        marginLeft: 5,
      },
    },
    margin: {
      marginBottom: 16,
    },
    login: {
      borderRadius: '15px 0 0 15px',

      '@media (max-width: 600px)': {
        padding: '5px 8px !important;',

        '& .MuiButton-label': {
          fontSize: 13,
        },
      },
    },
    desktop: {
      '@media (max-width: 1023px)': {
        display: 'none',
      },
    },
    mobile: {
      '@media (min-width: 1024px)': {
        display: 'none',
      },
      '@media print': {
        display: 'none',
      },
    },
    symbolCategory: {
      borderRadius: 0,

      '@media (max-width: 600px)': {
        padding: '5px 8px !important;',

        '& .MuiButton-label': {
          fontSize: 13,
        },
      },
    },
    symbol: {
      borderRadius: '0 15px 15px 0',

      '@media (max-width: 600px)': {
        padding: '5px 8px !important;',

        '& .MuiButton-label': {
          fontSize: 13,
        },
      },
    },
    selectedGroup: {
      backgroundColor: theme.palette.frequent.cultured,
    },
  }),
);
