import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      width: '100%',
      flexGrow: 1,
    },
    desktopView: {
      '@media (max-width: 990px)': {
        display: 'none',
      },
    },
    mobileView: {
      '@media (min-width: 991px)': {
        display: 'none',
      },
    },
    tableHeader: {
      marginTop: 9,
      color: theme.palette.frequent.wildBlue,
      fontSize: 15,
      '& th': {
        padding: '8px 24px 8px 16px',
        fontWeight: 500,
        fontSize: '1em',
      },

      '& th:first-child': {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
      },

      '& th:last-child': {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
      },
    },
    chartSection: {
      position: 'relative',
      height: 300,
      marginTop: 10,
    },
    listItem: {
      padding: '4px 6px 4px 8px',
      backgroundColor: theme.palette.frequent.background,
      color: theme.palette.lineGraph.tooltipText,
      fontSize: 17,
      borderRadius: 7,
      border: 'none',
      height: 41,
      margin: '3px 0',

      '&:hover': {
        backgroundColor: theme.palette.frequent.grey,
        color: theme.palette.lineGraph.tooltipText,
      },
    },
    listIcon: {
      minWidth: 'auto',
      marginRight: 8,
    },
    listItemIconNoChange: {
      height: 10,
    },
    listItemChangeIcon: {
      height: 14,
    },
    listSubItem: {
      fontSize: 12,
      borderRadius: 3,
      border: `1px solid ${theme.palette.secondary.light}`,
      height: 19,
      color: theme.palette.lineGraph.tooltipText,
      margin: '2px 0',
      paddingLeft: 8,
      paddingRight: 8,
    },
    subListItemIconNoChange: {
      height: 3,
      marginBottom: 3,
    },
    subListItemChangeIcon: {
      height: 10,
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: 0,
      justifyContent: 'center',
    },
    checkboxListItem: {
      border: `1px solid ${theme.palette.frequent.chineseWhite}`,
      color: theme.palette.lineGraph.text,

      '@media (max-width: 640px)': {
        paddingLeft: 4,
        paddingRight: 24,
      },

      '@media (max-width: 427px)': {
        paddingLeft: 4,
        paddingRight: 20,
      },
    },
    action: {
      right: 8,
    },
    icon: {
      '@media (max-width: 640px)': {
        fontSize: 14,
      },
    },
    checkbox: {
      color: theme.palette.frequent.chineseWhite,

      '&.Mui-disabled': {
        color: `${theme.palette.lineGraph.text}42`,
      },

      '&.Mui-checked': {
        color: theme.palette.lineGraph.text,

        '&.Mui-disabled': {
          color: `${theme.palette.lineGraph.text}42`,
        },
      },

      '@media (max-width: 600px)': {
        '& svg': {
          fontSize: 14,
        },
      },
    },
    checkboxListIcon: {
      minWidth: 30,

      '@media (max-width: 840px)': {
        minWidth: 11,
      },
    },
    buttonRow: {
      paddingTop: 84,

      '@media (max-width: 840px)': {
        justifyContent: 'center',
      },
    },
    checkboxText: {
      fontSize: 12,

      '@media (max-width: 600px)': {
        fontSize: 9,
      },
    },
    checkBoxListIconCommissions: {
      color: theme.palette.lineGraph.first,
    },
    checkBoxListIconWithdrawals: {
      color: theme.palette.lineGraph.second,
    },
    checkBoxListIconDeposits: {
      color: theme.palette.lineGraph.third,
    },
    checkBoxListIconLosses: {
      color: theme.palette.lineGraph.second,
    },
    checkBoxListIconProfits: {
      color: theme.palette.lineGraph.first,
    },
    emptyMessage: {
      color: theme.palette.lineGraph.text,
      fontStyle: 'italic',
      margin: '180px 0',
    },
    chartSectionBig: {
      margin: '53px 0 63px',
    },
    titleMessage: {
      fontSize: 18,
      fontWeight: 'bold',
      textAlign: 'left',
      color: theme.palette.frequent.davyGrey,
      paddingBottom: 18,
      '@media (max-width: 840px)': {
        fontSize: 14,
      },
    },
    tableHeaderDeposit: {
      borderRadius: 10,
      backgroundColor: theme.palette.frequent.lightWater,
    },
    tableHeaderWithdrawal: {
      backgroundColor: theme.palette.common.white,
    },
    tableRow: {
      color: theme.palette.frequent.fossilGrey,
      '& td': {
        padding: '12px 24px 12px 16px',
        fontSize: '1em',
      },
    },
    depositCell: {
      color: theme.palette.frequent.active,
    },
    withdrawalCell: {
      color: theme.palette.frequent.darkPastelRed,
    },
    partnershipCell: {
      textTransform: 'uppercase',
    },
    actionButtonsRight: {
      paddingLeft: 12,
    },
    tabsBar: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.frequent.davyGrey,
      boxShadow: 'none',
      borderBottom: `solid 1px ${theme.palette.frequent.wildBlue}`,

      '& .MuiTab-wrapper': {
        fontSize: 20,
        textTransform: 'none',
        fontWeight: 'normal',

        '@media (max-width: 840px)': {
          fontSize: 18,
        },
      },

      '& .Mui-selected .MuiTab-wrapper': {
        fontWeight: 'bold',
      },

      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.frequent.wildBlue,
      },
    },
    volumeTableRow: {
      color: theme.palette.frequent.fossilGrey,
      '& td': {
        padding: '6px 24px 8px 16px',
        fontSize: '1em',
      },
    },
    volumeTableItem: {
      padding: '7px 13px 6px 15px',
      borderRadius: 6,
      fontWeight: 'bold',
      width: 'fit-content',
    },
    volumeTableItem0: {
      backgroundColor: theme.palette.frequent.pinkLace,
    },
    volumeTableItem1: {
      backgroundColor: theme.palette.frequent.flax,
    },
    volumeTableItem2: {
      backgroundColor: theme.palette.frequent.platinum,
    },
    volumeTableItem3: {
      backgroundColor: theme.palette.frequent.aeroBlue,
    },
    volumeTableItem4: {
      backgroundColor: theme.palette.frequent.cornflowerBlue,
    },
    graphTitle: {
      textAlign: 'left',
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 7,

      '@media (max-width: 500px)': {
        fontSize: 18,
      },
    },
    buttons: {
      paddingTop: 78,
      flexDirection: 'row',
      alignItems: 'flex-start',
      alignContent: 'center',

      '@media (max-width: 550px)': {
        flexDirection: 'column',
      },
    },
    firstButton: {
      '@media (max-width: 550px)': {
        width: 260,
      },
    },
    secondButton: {
      paddingLeft: 24,

      '@media (max-width: 550px)': {
        paddingLeft: 0,
        paddingTop: 15,
        width: 260,
      },
    },
    button: {
      borderRadius: 20,
    },
    table: {
      '@media (max-width: 840px)': {
        display: 'none',
      },
    },
    mobileTable: {
      '@media (min-width: 841px)': {
        display: 'none',
      },
    },
    divider: {
      backgroundColor: theme.palette.secondary.light,
      margin: '19px 0 20px',
      height: 1,
    },
    lotsGraph: {
      marginTop: 42,

      '@media (min-width: 841px)': {
        marginTop: 18,
      },
    },
    volumeGraph: {
      marginTop: 32,

      '@media (min-width: 841px)': {
        marginTop: 20,
      },
    },
    label: {
      textTransform: 'uppercase',
    },
    value: {
      fontWeight: 'bold',
    },
  }),
);
