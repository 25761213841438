import React, { memo, FC, useCallback, useState, useEffect } from 'react';
import { Grid, Typography, Button, Radio, FormControlLabel } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Alert } from '@material-ui/lab';
import { cloneDeep } from 'lodash';
import { CopyIcon } from '../../CustomIcons';
import { InputField, FieldLabel, SelectField } from '../../Form';
import { Country, MasterCode } from '../PartnershipLinkDialogModel';
import { emptyLinkType, LinkType, ValueLabel, Broker } from '../../../models';
import { PlatformForLink, generateClientLink } from '../../../pages';
import { useApiError } from '../../../providers';
import { LightTooltip } from '../../LightTooltip';
import { useCommonStyles } from '../../../styles';
import { useStyles } from './styles';
import useGetTranslatedOptions from '../useGetTranslatedOptions';
import { trackAmplitudeData } from '../../../services/amplitudeService';

interface CreateClientLinkTabProps {
  translation: any;
  partnershipId: number;
  brokers: Broker[];
  countries: Country[];
  tradingAccountTypes: PlatformForLink[];
  masterCodes: MasterCode[];
  setLoading: (value: boolean) => void;
  formRef: any;
  onLinkGeneration: (partnershipId: number) => void;
}

export const CreateClientLinkTab: FC<CreateClientLinkTabProps> = memo(({ partnershipId, brokers, setLoading, formRef, countries, translation, tradingAccountTypes, onLinkGeneration, masterCodes }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { addError } = useApiError();
  const [brokerOptions, setBrokerOptions] = useState<ValueLabel[]>([]);
  const [countryOptions, setCountryOptions] = useState<ValueLabel[]>([]);
  const [languageOptions, setLanguageOptions] = useState<ValueLabel[]>([]);
  const [platformOptions, setPlatformOptions] = useState<ValueLabel[]>([]);
  const [linkTypeOptions, setLinkTypeOptions] = useState<ValueLabel[]>([]);
  const [masterCodeOptions, setMasterCodeOptions] = useState<ValueLabel[]>([]);
  const [selectedLinkTypeOption, setSelectedLinkTypeOption] = useState<ValueLabel>();
  const getOptions = useGetTranslatedOptions(translation);
  const [currencyOptions, setCurrencyOptions] = useState<ValueLabel[]>([]);
  const [generatedLink, setGeneratedLink] = useState<string>('');
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  useEffect(() => {
    formRef.current.resetForm();
  }, [formRef]);

  useEffect(() => {
    setBrokerOptions(getOptions(brokers, 'name', 'id'));
    setCountryOptions(getOptions(countries, 'name', 'countryId'));
  }, [brokers, countries, getOptions]);

  useEffect(() => {
    setPlatformOptions([{ value: '-1', label: translation.text_1668 }, ...getOptions(tradingAccountTypes ?? [], 'name', 'directCode')]);
  }, [getOptions, tradingAccountTypes, translation.text_1668]);

  const validationSchema = Yup.object().shape({
    brokerId: Yup.string().required(translation.text_586),
    countryId: Yup.string().required(translation.text_586),
    platformTradingAccountType: Yup.string().required(translation.text_586),
    language: Yup.string().required(translation.text_586),
    linkType: Yup.string().required(translation.text_586),
    currency: Yup.string().required(translation.text_586),
    shortCode: Yup.string().max(50, translation.text_244),
  });

  const handleLinkCopy = useCallback(async () => {
    await navigator.clipboard.writeText(generatedLink);
    enqueueSnackbar(translation.text_1628, { variant: 'success' });

    const eventProperties = {
      linkType: 'link copied',
    };
    trackAmplitudeData('partnerPortal_action_regLinkCopy', eventProperties);
  }, [enqueueSnackbar, generatedLink, translation]);

  const selectLanguages = useCallback(
    (broker) => {
      const selectedBroker = brokers?.filter((options: any) => options.id === broker) as any;

      setLanguageOptions(getOptions(selectedBroker[0]?.supportedLanguages ?? [], 'displayName', 'code'));
    },
    [brokers, getOptions],
  );

  const handleBrokerChange = useCallback(
    (e, setFieldValue) => {
      const brokerId = e.target.value;

      selectLanguages(brokerId);
      setFieldValue('brokerId', brokerId);
    },
    [selectLanguages],
  );

  const updateCurrencyOptions = useCallback(
    (linkType, values) => {
      let selectedLinkType: LinkType;

      if (values.platformTradingAccountType === '-1') {
        // Should present distinct currencies - on selected linkType
        const linkTypes = tradingAccountTypes?.flatMap((platform) => platform.linkTypes) ?? [];

        const commonCurrencies = linkTypes.flatMap((e) => e.currencies).filter((currency, index, array) => array.indexOf(currency) === index && linkTypes.every((e) => e.currencies.includes(currency)));
        const selectLinkType = linkTypes.find((x) => x.linkType === linkType) ?? emptyLinkType();

        selectedLinkType = commonCurrencies.length > 0 ? { ...selectLinkType, currencies: commonCurrencies } : emptyLinkType();
      } else {
        // Get currencies of selected platform-linkType combo
        const platform = tradingAccountTypes?.find((x) => x.directCode === values.platformTradingAccountType);
        selectedLinkType = platform?.linkTypes?.find((x) => x.linkType === linkType) ?? emptyLinkType();
      }
      const currencies =
        selectedLinkType?.currencies?.map((currencyName) => ({
          value: currencyName,
          label: currencyName,
        })) ?? [];
      setCurrencyOptions([{ value: '-1', label: translation.text_1667 }, ...currencies]);
    },
    [tradingAccountTypes, translation],
  );

  const handleLinkTypeClick = useCallback(
    (option, values, setFieldValue) => {
      values.linkType = option.value;
      setSelectedLinkTypeOption(option);
      setFieldValue('linkType', option.value);
      updateCurrencyOptions(option.value, values);
      setFieldValue('currency', '');
    },
    [updateCurrencyOptions],
  );

  const filterMasterCodes = useCallback(
    (values) => {
      let codes = masterCodes;

      if (values.platformTradingAccountType !== '-1') {
        const accountType = tradingAccountTypes.find((t) => t.directCode === values.platformTradingAccountType);
        codes = codes.filter((m) => m.accountTypeId === accountType?.id);
      }

      if (values.currency !== '-1' && values.currency !== '') {
        codes = codes.filter((m) => m.currency === values.currency);
      }

      setMasterCodeOptions(getOptions(codes, 'masterCode', 'masterCode'));
    },
    [masterCodes, tradingAccountTypes, getOptions],
  );

  const handlePlatformChange = useCallback(
    (e, setFieldValue, values) => {
      const platformDirectCode = e.target.value;
      let options = [];
      if (platformDirectCode === '-1') {
        // Should present distinct link types
        const linkTypes = tradingAccountTypes?.flatMap((platform) => platform.linkTypes) ?? [];
        const typeOptions = getOptions(linkTypes, 'linkTypeText', 'linkType');
        options = typeOptions.filter((option, index, self) => index === self.findIndex((t) => t.value === option.value && t.label === option.label));
      } else {
        const platform = tradingAccountTypes?.find((x) => x.directCode === platformDirectCode);
        options = getOptions(platform?.linkTypes ?? [], 'linkTypeText', 'linkType');
      }
      setLinkTypeOptions(options);
      if (options.length > 0) {
        setSelectedLinkTypeOption(options[0]);
        values.linkType = options[0].value;
        values.platformTradingAccountType = platformDirectCode;
        updateCurrencyOptions(options[0].value, values);
        setFieldValue('linkType', options[0].value);
      }
      setFieldValue('platformTradingAccountType', platformDirectCode);
      setFieldValue('currency', '');

      if (values.linkType !== '') updateCurrencyOptions(values.linkType, values);

      filterMasterCodes(values);
    },
    [tradingAccountTypes, updateCurrencyOptions, filterMasterCodes, getOptions],
  );

  const handleCurrencyChange = useCallback(
    (e, setFieldValue, values) => {
      const currency = e.target.value;
      values.currency = currency;
      setFieldValue('currency', currency);

      filterMasterCodes(values);
    },
    [filterMasterCodes],
  );

  const handleMasterCodeChange = useCallback(
    (e, setFieldValue, values) => {
      const code = e.target.value;

      const masterCode = masterCodes.find((m) => m.masterCode === code);

      if (masterCode) {
        if (values.platformTradingAccountType === '-1' && values.currency === '-1') {
          const accountType = tradingAccountTypes.find((t) => t.id === masterCode.accountTypeId);
          if (accountType) {
            setFieldValue('platformTradingAccountType', accountType.directCode);
            values.platformTradingAccountType = accountType.directCode;
          }
          setFieldValue('currency', masterCode.currency);
          values.currency = masterCode.currency;
          setFieldValue('masterCode', code);
        } else if (values.platformTradingAccountType === '-1' && values.currency === masterCode.currency) {
          const accountType = tradingAccountTypes.find((t) => t.id === masterCode.accountTypeId);
          if (accountType) {
            setFieldValue('platformTradingAccountType', accountType.directCode);
            values.platformTradingAccountType = accountType.directCode;
          }
          setFieldValue('masterCode', code);
        } else {
          const accountType = tradingAccountTypes.find((t) => t.directCode === values.platformTradingAccountType);
          if (accountType) {
            if (accountType.id === masterCode.accountTypeId && values.currency === '-1') {
              setFieldValue('currency', masterCode.currency);
              values.currency = masterCode.currency;
              setFieldValue('masterCode', code);
            }
            if (accountType.id === masterCode.accountTypeId && values.currency === masterCode.currency) {
              setFieldValue('masterCode', code);
            }
          }
        }
        filterMasterCodes(values);
      }
    },
    [tradingAccountTypes, masterCodes, filterMasterCodes],
  );

  const handleLinkGeneration = useCallback(
    (values, { setSubmitting, setFieldValue }) => {
      const transformedValues = cloneDeep(values);

      Object.keys(transformedValues).forEach((key) => {
        if (transformedValues[key] === '-1') {
          transformedValues[key] = null;
        }
      });

      setLoading(true);
      generateClientLink(transformedValues, partnershipId)
        .then((response) => {
          const eventProperties = {
            linkType: 'Client link',
          };
          trackAmplitudeData('partnerPortal_action_regLinkCreation', eventProperties);

          setGeneratedLink(response.data.link);
          setFieldValue('shortCode', '');
          onLinkGeneration(partnershipId);
        })
        .catch((error) => {
          addError(JSON.stringify(error.response));
        })
        .finally(() => {
          setLoading(false);
          setSubmitting(false);
        });
    },
    [setLoading, addError, onLinkGeneration, partnershipId],
  );

  const submitForm = useCallback(() => {
    formRef?.current?.submitForm();
  }, [formRef]);

  return (
    <>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Formik
          innerRef={formRef}
          onSubmit={handleLinkGeneration}
          enableReinitialize
          initialValues={{
            brokerId: '',
            countryId: '',
            platformTradingAccountType: '',
            language: '',
            linkType: '',
            currency: '',
            shortCode: '',
            masterCode: '',
          }}
          validationSchema={validationSchema}
        >
          {({ setFieldValue, values, touched, errors }) => (
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid className={classes.fieldContainer}>
                    <SelectField
                      name="brokerId"
                      label={translation.text_6240}
                      placeholder={translation.text_6241}
                      options={brokerOptions}
                      errors={touched.brokerId ? errors.brokerId : undefined}
                      isRequired
                      onChange={(e) => handleBrokerChange(e, setFieldValue)}
                      selectClass={classes.selectField}
                      selectIconClass={classes.selectIconClass}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.fieldContainer}>
                    <FieldLabel label={translation.text_6243} isRequired />
                    {linkTypeOptions &&
                      linkTypeOptions.map((option) => (
                        <FormControlLabel value={option.value} label={option.label} control={<Radio checked={selectedLinkTypeOption === option} onChange={() => handleLinkTypeClick(option, values, setFieldValue)} value={option.value} />} />
                      ))}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <Grid className={classes.fieldContainer}>
                    <SelectField
                      name="countryId"
                      label={translation.text_6244}
                      placeholder={translation.text_6245}
                      options={countryOptions}
                      errors={touched.countryId ? errors.countryId : undefined}
                      isRequired
                      selectClass={classes.selectField}
                      selectIconClass={classes.selectIconClass}
                      disabled={values.brokerId === ''}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid className={classes.fieldContainer}>
                    <SelectField
                      name="currency"
                      placeholder={translation.text_6246}
                      label={translation.text_6148}
                      errors={touched.currency ? errors.currency : undefined}
                      options={currencyOptions}
                      onChange={(e) => handleCurrencyChange(e, setFieldValue, values)}
                      selectClass={classes.selectField}
                      isRequired
                      selectIconClass={classes.selectIconClass}
                      disabled={values.brokerId === ''}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid className={classes.fieldContainer}>
                    <SelectField
                      name="platformTradingAccountType"
                      label={translation.text_6247}
                      placeholder={translation.text_6248}
                      options={platformOptions}
                      errors={touched.platformTradingAccountType ? errors.platformTradingAccountType : undefined}
                      onChange={(e) => handlePlatformChange(e, setFieldValue, values)}
                      isRequired
                      selectClass={classes.selectField}
                      selectIconClass={classes.selectIconClass}
                      disabled={values.brokerId === ''}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.fieldContainer}>
                    <SelectField
                      name="language"
                      placeholder={translation.text_6249}
                      label={translation.text_6162}
                      options={languageOptions}
                      errors={touched.language ? errors.language : undefined}
                      isRequired
                      selectClass={classes.selectField}
                      selectIconClass={classes.selectIconClass}
                      disabled={values.brokerId === ''}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <Grid className={classes.shortcodeContainer}>
                    <InputField
                      name="shortCode"
                      label={translation.text_6250}
                      icon={
                        <LightTooltip title={translation.text_6251}>
                          <InfoOutlined className={classes.input} />
                        </LightTooltip>
                      }
                      errors={touched.shortCode ? errors.shortCode : undefined}
                      inputClass={classes.inputRoot}
                      disabled={values.brokerId === ''}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.fieldContainer}>
                    <SelectField
                      name="masterCode"
                      placeholder={translation.select_master_code}
                      label={translation.master_code}
                      options={masterCodeOptions}
                      isRequired={false}
                      selectClass={classes.selectField}
                      selectIconClass={classes.selectIconClass}
                      disabled={masterCodeOptions.length === 0}
                      onChange={(e) => handleMasterCodeChange(e, setFieldValue, values)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Grid className={classes.generateButtonContainer}>
                    <Button variant="contained" disableRipple onClick={submitForm} className={`${commonClasses.buttonContained} ${classes.button} ${classes.generateButton}`}>
                      {translation.text_6252}
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Alert icon={false} severity="success" className={classes.generatedLinkContainer}>
                    <div className={classes.link}>
                      <Typography component="span" className={classes.generetedLinkTittle}>
                        {translation.text_6253}
                      </Typography>
                      {generatedLink !== '' && (
                        <Typography component="span" className={classes.generatedLink}>
                          {generatedLink.substring(0, 37)}...
                        </Typography>
                      )}
                    </div>
                    {generatedLink !== '' ? (
                      <div className={classes.rightContainer}>
                        <Button className={classes.copyLinkButton} onClick={handleLinkCopy}>
                          <CopyIcon />{' '}
                          <Typography component="span" className={classes.copyText}>
                            {translation.text_6254}
                          </Typography>
                        </Button>
                      </div>
                    ) : (
                      <div className={classes.link}>
                        <Typography component="span" className={classes.generetedLinkDefaultText}>
                          {translation.text_6255}
                        </Typography>
                      </div>
                    )}
                  </Alert>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </Grid>
    </>
  );
});
