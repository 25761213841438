import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Tab, TableCell, TableRow, Tabs } from '@material-ui/core';
import { useStyles } from './styles';
import { CustomTable, Dialog, ExportButtons, TabPanel } from '../../../../components';
import { a11yProps, getTranslation } from '../../../../utils';
import { getIBPartnershipClientsAndAccounts } from '../../PartnersHierarchyService';
import { useApiError } from '../../../../providers';

interface Props {
  data: any;
  trigger: number | undefined;
  handleCancel: () => void;
  translation: any;
}

export const ClientListDialog: FC<Props> = ({ data, trigger, handleCancel, translation }) => {
  const clientsColumns = useMemo(() => [translation.text_6394, translation.text_6395, translation.text_6396, translation.text_6397, translation.text_6398, translation.text_6399], [translation]);
  const accountsColumns = useMemo(() => [translation.text_6400, translation.text_6396, translation.text_6397, translation.text_6398, translation.text_6399], [translation]);

  const classes = useStyles();
  const { addError } = useApiError();
  const [clientsTableData, setClientsTableData] = useState<any>([]);
  const [accountsTableData, setAccountsTableData] = useState<any>([]);
  const [columnNames, setColumnNames] = useState<string[]>(clientsColumns);
  const [value, setValue] = useState<number>(0);

  const formatReportData = useCallback(
    (clients: any[]) => {
      const clientsResult: any[] = [];
      const accountsResult: any[] = [];

      clients.forEach((client) => {
        const item = {
          clientName: client.name,
          login: Array.from(new Set(client.accounts.map((account: any) => account.login))),
          platformGroup: Array.from(new Set(client.accounts.map((account: any) => account.platformGroup))),
          currency: Array.from(new Set(client.accounts.map((account: any) => account.currency))),
          platform: Array.from(new Set(client.accounts.map((account: any) => account.platform))),
          tradingAccountType: Array.from(new Set(client.accounts.map((account: any) => account.tradingAccountType))),
        };

        client.accounts.forEach((account: any) => {
          const accountItem = {
            login: account.login,
            platformGroup: account.platformGroup,
            currency: account.currency,
            platform: account.platform,
            tradingAccountType: account.tradingAccountType,
          };
          accountsResult.push(accountItem);
        });

        clientsResult.push(item);
      });

      setClientsTableData(clientsResult);
      setAccountsTableData(accountsResult);
    },
    [setClientsTableData],
  );

  const getClientsAndAccounts = useCallback(
    async (partnershipId: number) => {
      try {
        const response = await getIBPartnershipClientsAndAccounts(partnershipId);
        formatReportData(response.data);
      } catch (error) {
        addError(JSON.stringify(error.response));
      }
    },
    [formatReportData, addError],
  );

  useEffect(() => {
    if (data !== null) {
      (async () => {
        await getClientsAndAccounts(data.partnershipId);
      })();
    }
  }, [getClientsAndAccounts, data]);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);

    if (newValue === 1) {
      setColumnNames(accountsColumns);
    } else {
      setColumnNames(clientsColumns);
    }
  };

  const renderHeader = useMemo((): any[] => {
    const result: any[] = [];
    columnNames.forEach((columnName) => {
      result.push(
        <TableCell key={columnName} align={columnName === clientsColumns[0] || columnName === accountsColumns[0] ? 'left' : 'center'}>
          {columnName}
        </TableCell>,
      );
    });
    return result;
  }, [columnNames, clientsColumns, accountsColumns]);

  const renderClientRows = useMemo((): any[] => {
    const result: any[] = [];

    clientsTableData.forEach((row: any) => {
      result.push(
        <TableRow key={row.login}>
          <TableCell align="left" className={classes.cell}>
            {row.clientName}
          </TableCell>
          <TableCell align="center" className={classes.cell}>
            {row.login.map((l: any) => (
              <span key={l}>
                {l}
                <br />
              </span>
            ))}
          </TableCell>
          <TableCell align="center" className={classes.cell}>
            {row.platformGroup.map((p: any) => (
              <span key={p}>
                {p}
                <br />
              </span>
            ))}
          </TableCell>
          <TableCell align="center" className={classes.cell}>
            {row.currency.map((c: any) => (
              <span key={c}>
                {c}
                <br />
              </span>
            ))}
          </TableCell>
          <TableCell align="center" className={classes.cell}>
            {row.platform.map((p: any) => (
              <span key={p}>
                {p}
                <br />
              </span>
            ))}
          </TableCell>
          <TableCell align="center" className={classes.cell}>
            {row.tradingAccountType.map((t: any) => (
              <span key={t}>
                {getTranslation(translation, t)}
                <br />
              </span>
            ))}
          </TableCell>
        </TableRow>,
      );
    });

    return result;
  }, [clientsTableData, classes.cell, translation]);

  const renderAccountRows = useMemo((): any[] => {
    const result: any[] = [];

    accountsTableData.forEach((row: any) => {
      result.push(
        <TableRow key={row.login}>
          <TableCell align="left">{row.login}</TableCell>
          <TableCell align="center">{row.platformGroup}</TableCell>
          <TableCell align="center">{row.currency}</TableCell>
          <TableCell align="center">{row.platform}</TableCell>
          <TableCell align="center">{getTranslation(translation, row.tradingAccountType)}</TableCell>
        </TableRow>,
      );
    });

    return result;
  }, [accountsTableData, translation]);

  const renderClientsTableData = (): string[][] => {
    const rows: string[][] = [];
    clientsTableData.forEach((row: any) => {
      const tableRow: string[] = [row.clientName, row.login, row.platformGroup, row.currency, row.platform, [getTranslation(translation, row.tradingAccountType[0])]];
      rows.push(tableRow);
    });

    return rows;
  };

  const renderAccountsTableData = (): string[][] => {
    const rows: string[][] = [];
    accountsTableData.forEach((row: any) => {
      const tableRow: string[] = [row.login, row.platformGroup, row.currency, row.platform, getTranslation(translation, row.tradingAccountType)];
      rows.push(tableRow);
    });

    return rows;
  };

  return (
    <>
      {data !== null && (
        <Dialog
          trigger={trigger}
          title={`${translation.text_6401} ${data.partnershipCode.toString().toUpperCase()} - ${translation.text_6402} (${data.clientsNumber}) ${translation.text_6403} (${data.accountsNumber})`}
          subTitle={
            <ExportButtons
              exportFileName={value === 0 ? translation.clients : translation.tradingaccounts}
              columns={columnNames}
              exportTableData={value === 0 ? renderClientsTableData() : renderAccountsTableData()}
              isEnabled
              reportNameInEn={value === 0 ? 'Clients' : 'Trading Accounts'}
            />
          }
          onClose={() => handleCancel()}
          actions={[
            {
              key: '1',
              title: translation.text_6404,
              onClick: () => handleCancel(),
              isPrimary: false,
            },
          ]}
        >
          <Grid container direction="column" className={classes.root}>
            <p>
              <b>{translation.text_6405}</b>
            </p>
            <Tabs value={value} onChange={handleChange} className={classes.tabs}>
              <Tab className={classes.clientTab} label={translation.text_6406} {...a11yProps(0)} />
              <Tab className={classes.accountTab} label={translation.text_6407} {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <CustomTable headerCells={renderHeader} rows={renderClientRows} columnsNumber={columnNames.length} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CustomTable headerCells={renderHeader} rows={renderAccountRows} columnsNumber={columnNames.length} />
            </TabPanel>
          </Grid>
        </Dialog>
      )}
    </>
  );
};
