import { LoginForm } from './LoginModels';
import { instanceApi } from '../../services';

export const login = (data: LoginForm): Promise<any> => instanceApi.post('auth/Authenticate/Login', data);
export const getUserLoginData = (): Promise<any> => instanceApi.get('api/user/getuserlogindata');

export const getTranslations = (): Promise<any> => instanceApi.get(`api/settings/gettranslations`);

export const getTranslationsByBrokerId = (brokerId?: string): Promise<any> => instanceApi.get(`api/settings/gettranslationsbybroker?brokerId=${brokerId}`);

export const getBrokers = (): Promise<any> => instanceApi.get('api/brokersmanagement/getbrokers');

export const loginRepresentative = (data: LoginForm): Promise<any> => instanceApi.post('auth/Authenticate/Login', data);
