import React, { FC, useEffect, useState } from 'react';
import { useStyles } from './styles';
import { useAppData } from '../../providers';

interface Props {
  translation: any;
}

export const Logo: FC<Props> = ({ translation }) => {
  const classes = useStyles();
  const { logoUrl } = useAppData();
  const [logo, setLogo] = useState<string>('');

  useEffect(() => {
    setLogo(logoUrl);
  }, [logoUrl]);

  return (
    <div className={classes.LogoContainer}>
      <div className={classes.imageContent}>
        <img src={logo} alt="" className={classes.logoImg} />
      </div>
      <p className={classes.LogoTitle}>{translation.text_1361}</p>
    </div>
  );
};
