import { Button, Grid } from '@material-ui/core';
import React, { FC, useCallback, useState, useEffect, useMemo } from 'react';
import { useStyles } from './styles';
import { useCommonStyles } from '../../../styles';
import { Dialog } from '../../Dialog';
import { FieldLabel } from '../../Form';
import { ColumnsSelect } from '../ColumnsSelect';
import { useAppData } from '../../../providers';
import { ExportButtons } from '../../ExportButtons';
import { PrintButton } from '../../PrintButton';
import { SendByEmail } from '../../SendByEmail';

interface Props {
  title: any;
  subTitle: any;
  exportTableData: string[][];
  reportColumns: string[];
  selectedColumns: string[];
  exportReportName: string;
  reportNameInEn: string;
  trigger: number | undefined;
  handleGroupBy: (groupBy: 'Login' | 'Group' | 'Symbol' | undefined) => void;
  handleSelectColumns: (selectedColumns: string[]) => void;
  handleCancel: () => void;
  children: JSX.Element;
  sendEmail: () => void;
}

export const ReportDetailsDialog: FC<Props> = ({ title, subTitle, exportTableData, trigger, handleCancel, reportColumns, selectedColumns, reportNameInEn, exportReportName, children, handleGroupBy, handleSelectColumns, sendEmail }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [groupBy, setGroupBy] = useState<'Login' | 'Group' | 'Symbol' | undefined>(undefined);
  const { translations } = useAppData();
  const [translation, setTranslation] = useState<any>([]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  const selectGroupBy = useCallback(
    (option) => {
      let newValue = option;
      if (option === groupBy) {
        newValue = undefined;
      }
      setGroupBy(newValue);
      handleGroupBy(newValue);
    },
    [groupBy, handleGroupBy],
  );

  const loginClasses = useMemo(() => `${commonClasses.buttonContained} ${classes.login}`, [classes, commonClasses]);

  const symbolCategoryClasses = useMemo(() => `${commonClasses.buttonContained} ${classes.symbolCategory}`, [classes, commonClasses]);

  const symbolClasses = useMemo(() => `${commonClasses.buttonContained} ${classes.symbol}`, [classes, commonClasses]);

  const memorizedLoginValue = useMemo(() => 'Login', []);
  const memorizedSymbolCategoryValue = useMemo(() => 'Group', []);
  const memorizedSymbolValue = useMemo(() => 'Symbol', []);

  return (
    <Dialog
      trigger={trigger}
      title={title}
      subTitle={subTitle}
      actions={[
        {
          key: '1',
          title: translation.text_1551,
          onClick: () => handleCancel(),
          isPrimary: false,
        },
      ]}
    >
      <Grid container direction="column" spacing={2} className={`${classes.root} ${classes.dialog}`}>
        <Grid container direction="row" alignContent="flex-start" alignItems="flex-end" justify="space-between" className={classes.margin}>
          <Grid item xs={4}>
            <FieldLabel label={translation.text_5718} />
            <Grid container direction="row" alignContent="flex-start" alignItems="center">
              <Grid item>
                <Button
                  variant="outlined"
                  className={groupBy === 'Login' ? classes.selectedGroup : ''}
                  classes={{
                    root: loginClasses,
                  }}
                  onClick={() => selectGroupBy(memorizedLoginValue)}
                >
                  {translation.text_6001}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  className={groupBy === 'Group' ? classes.selectedGroup : ''}
                  classes={{
                    root: symbolCategoryClasses,
                  }}
                  onClick={() => selectGroupBy(memorizedSymbolCategoryValue)}
                >
                  {translation.text_6469}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  className={groupBy === 'Symbol' ? classes.selectedGroup : ''}
                  classes={{
                    root: symbolClasses,
                  }}
                  onClick={() => selectGroupBy(memorizedSymbolValue)}
                >
                  {translation.text_1013}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container justify="flex-start">
              <Grid item>
                <ColumnsSelect reportColumns={reportColumns} selectedColumns={selectedColumns} handleSelectColumns={handleSelectColumns} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container direction="row" justify="flex-end" spacing={2}>
              <Grid item>
                <PrintButton translation={translation} title={exportReportName} columnNumber={selectedColumns.length} isEnabled reportNameInEn={reportNameInEn}>
                  {children}
                </PrintButton>
              </Grid>
              <Grid item>
                <SendByEmail sendEmail={sendEmail} translation={translation} title={exportReportName} content={children} isEnabled />
              </Grid>
              <Grid item>
                <ExportButtons exportFileName={exportReportName} columns={reportColumns} exportTableData={exportTableData} isEnabled reportNameInEn={reportNameInEn} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {children}
      </Grid>
    </Dialog>
  );
};
