import React, { memo, FC, useCallback, useState, Fragment, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Button, Typography } from '@material-ui/core';
import { ArrowDropDownSharp } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { getTranslation } from 'src/utils';
import useStyles from './styles';
import { useApiError, useAppData, useLoading } from '../../../providers';
import { Link } from '../PartnershipLinkDialogModel';
import { CopyIcon, LinkDetailsIcon } from '../../CustomIcons';
import { createDefaultLink, getPropertiesForClientSetupURL, getPropertiesForSubPartnershipSetupURL } from '../../../pages';
import { trackAmplitudeData } from '../../../services/amplitudeService';
import { useCommonStyles } from '../../../styles';

interface ExistingLinksTabProps {
  partnershipId: number;
  canCreateDefaultLink: boolean;
  clientLinks: Link[];
  subPartnershipLinks: Link[];
  mlmLinks: Link[];
  setLoading: (value: boolean) => void;
}

export const ExistingLinksTab: FC<ExistingLinksTabProps> = memo(({ partnershipId, canCreateDefaultLink, clientLinks, subPartnershipLinks, mlmLinks, setLoading }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { addError } = useApiError();
  const { setIsLoading } = useLoading();
  const [expandedClientLink, setExpandedClientLink] = useState<string | false>(false);
  const [expandedSubPartnershipLink, setExpandedSubPartnershipLink] = useState<string | false>(false);
  const [expandedMlmLink, setExpandedMlmLink] = useState<string | false>(false);
  const [linkInfo, setLinkInfo] = useState<any | false>(false);
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { translations } = useAppData();
  const [translation, setTranslation] = useState<any>([]);
  const [defaultLink, setDefaultLink] = useState<Link>();

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  useEffect(() => {
    if (clientLinks.length > 0) {
      const link = clientLinks.find((l) => l.isDefault);
      if (link) {
        setDefaultLink(link);
      }
    } else {
      setDefaultLink(undefined);
    }
  }, [clientLinks]);

  const handleLinkCopy = useCallback(
    async (event, link) => {
      event.stopPropagation();
      await navigator.clipboard.writeText(link);
      enqueueSnackbar(translation.text_1637, { variant: 'success' });

      const eventProperties = {
        linkType: 'link copied',
      };
      trackAmplitudeData('partnerPortal_action_regLinkCopy', eventProperties);
    },
    [enqueueSnackbar, translation.text_1637],
  );

  const handleExpandedClientAccordionChange = (link: string) => (event: any, isExpanded: boolean) => {
    setIsLoading(true);
    getPropertiesForClientSetupURL(link)
      .then(({ data }) => {
        if (isExpanded) {
          setLinkInfo({
            broker: data.brokerName,
            shortCode: data.shortCode !== undefined && data.shortCode !== null ? data.shortCode : '-',
            language: data.language,
            country: data.country,
            masterCode: data.masterCode,
          });
        }
        setExpandedClientLink(isExpanded ? link : false);
      })
      .catch((error) => {
        addError(JSON.stringify(error.response));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleExpandedSubPartnershipAccordionChange = (link: string, shortCode: string) => (event: any, isExpanded: boolean) => {
    setIsLoading(true);
    getPropertiesForSubPartnershipSetupURL(shortCode)
      .then(({ data }) => {
        if (isExpanded) {
          setLinkInfo({
            broker: data.brokerName,
            shortCode: data.shortCode !== undefined && data.shortCode !== null ? data.shortCode : '-',
            language: data.language,
            percentage: data.percentage,
          });
        }
        setExpandedSubPartnershipLink(isExpanded ? link : false);
      })
      .catch((error) => {
        addError(JSON.stringify(error.response));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleExpandedMlmAccordionChange = (link: string, shortCode: string) => (event: any, isExpanded: boolean) => {
    setIsLoading(true);
    getPropertiesForSubPartnershipSetupURL(shortCode)
      .then(({ data }) => {
        if (isExpanded) {
          setLinkInfo({
            broker: data.brokerName,
            shortCode: data.shortCode !== undefined && data.shortCode !== null ? data.shortCode : '-',
            language: data.language,
            percentage: data.percentage,
          });
        }
        setExpandedMlmLink(isExpanded ? link : false);
      })
      .catch((error) => {
        addError(JSON.stringify(error.response));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const generateDefaultLink = useCallback(async () => {
    setLoading(true);

    try {
      const response = await createDefaultLink(partnershipId);
      const eventProperties = {
        linkType: 'Default link',
      };
      trackAmplitudeData('partnerPortal_action_regLinkCreation', eventProperties);

      const link = response.data;
      link.isDefault = true;
      setDefaultLink(link);
      clientLinks.push(link);
    } catch (error) {
      addError(JSON.stringify(error.response));
    } finally {
      setLoading(false);
    }
  }, [addError, partnershipId, setLoading, clientLinks]);

  return (
    <>
      <Grid container spacing={2}>
        {canCreateDefaultLink && (
          <Grid item xs={12} className={classes.selectGridContaner}>
            <Accordion className={classes.accordionContainer}>
              <AccordionSummary expandIcon={<ArrowDropDownSharp />} className={classes.accordionHeader}>
                <Typography className={classes.accordionHeaderTitle}>{translation.default_links}</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetails }}>
                {defaultLink && (
                  <Fragment key={defaultLink.shortCode}>
                    <Accordion classes={{ root: classes.accordion }} expanded={expandedClientLink === defaultLink.link} onChange={handleExpandedClientAccordionChange(defaultLink.link)}>
                      <AccordionSummary classes={{ content: classes.accordionContent }}>
                        <Typography className={classes.link}>{defaultLink.link.substring(0, 47)}...</Typography>
                        <div className={classes.rightContainer}>
                          <Button
                            className={classes.expandButtons}
                            onClick={() => {
                              handleExpandedClientAccordionChange(defaultLink.link);
                            }}
                          >
                            <LinkDetailsIcon width={10} />
                          </Button>
                          <Button onClick={(event) => handleLinkCopy(event, defaultLink.link)} className={classes.copyButtons}>
                            <CopyIcon width={10} />
                          </Button>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_6270}</b>: {linkInfo.broker}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_6682}</b>: {linkInfo.language}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_432}</b>: {getTranslation(translation, linkInfo?.country || '')}
                            </Typography>
                          </Grid>
                          {linkInfo.masterCode && (
                            <Grid item xs={6}>
                              <Typography component="span">
                                <b>{translation.master_code}</b>: {linkInfo.masterCode}
                              </Typography>
                            </Grid>
                          )}
                          <Grid item xs={12} className={classes.shortCode}>
                            <Typography component="span">
                              <b>{translation.text_6272}</b>: {linkInfo.shortCode}
                            </Typography>
                            <Button onClick={(event) => handleLinkCopy(event, linkInfo.shortCode)} className={classes.copyCodeButtons}>
                              <CopyIcon width={10} />
                            </Button>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Fragment>
                )}
                {canCreateDefaultLink && defaultLink === undefined && (
                  <Grid item xs={12}>
                    <Grid className={classes.generateTextContainer}>
                      <Typography component="span">
                        <b>{translation.you_dont_have_default_links_yet}</b>
                      </Typography>
                    </Grid>
                    <Grid className={classes.generateButtonContainer}>
                      <Button variant="contained" disableRipple onClick={generateDefaultLink} className={`${commonClasses.buttonContained} ${classes.button} ${classes.generateButton}`}>
                        {translation.create_default_link}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
        {clientLinks.length > 0 && (
          <Grid item xs={12} className={classes.selectGridContaner}>
            <Accordion className={classes.accordionContainer}>
              <AccordionSummary expandIcon={<ArrowDropDownSharp />} className={classes.accordionHeader}>
                <Typography className={classes.accordionHeaderTitle}>{translation.text_419}</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetails }}>
                {clientLinks.map((link) => (
                  <Fragment key={link.shortCode}>
                    <Accordion classes={{ root: classes.accordion }} expanded={expandedClientLink === link.link} onChange={handleExpandedClientAccordionChange(link.link)}>
                      <AccordionSummary classes={{ content: classes.accordionContent }}>
                        <Typography className={classes.link}>{link.link.substring(0, 47)}...</Typography>
                        <div className={classes.rightContainer}>
                          <Button
                            className={classes.expandButtons}
                            onClick={() => {
                              handleExpandedClientAccordionChange(link.link);
                            }}
                          >
                            <LinkDetailsIcon width={10} />
                          </Button>
                          <Button onClick={(event) => handleLinkCopy(event, link.link)} className={classes.copyButtons}>
                            <CopyIcon width={10} />
                          </Button>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_6270}</b>: {linkInfo.broker}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_6682}</b>: {linkInfo.language}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_432}</b>: {getTranslation(translation, linkInfo?.country || '')}
                            </Typography>
                          </Grid>
                          {linkInfo.masterCode && (
                            <Grid item xs={6}>
                              <Typography component="span">
                                <b>{translation.master_code}</b>: {linkInfo.masterCode}
                              </Typography>
                            </Grid>
                          )}
                          <Grid item xs={12} className={classes.shortCode}>
                            <Typography component="span">
                              <b>{translation.text_6272}</b>: {linkInfo.shortCode}
                            </Typography>
                            <Button onClick={(event) => handleLinkCopy(event, linkInfo.shortCode)} className={classes.copyCodeButtons}>
                              <CopyIcon width={10} />
                            </Button>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Fragment>
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
        {subPartnershipLinks.length > 0 && (
          <Grid item xs={12} className={classes.selectGridContaner}>
            <Accordion className={classes.accordionContainer}>
              <AccordionSummary expandIcon={<ArrowDropDownSharp />} className={classes.accordionHeader}>
                <Typography className={classes.accordionHeaderTitle}>{translation.sub_Partnership_Links}</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetails }}>
                {subPartnershipLinks.map((link) => (
                  <Fragment key={link.shortCode}>
                    <Accordion classes={{ root: classes.accordion }} expanded={expandedSubPartnershipLink === link.link} onChange={handleExpandedSubPartnershipAccordionChange(link.link, link.shortCode)}>
                      <AccordionSummary classes={{ content: classes.accordionContent }}>
                        <Typography className={classes.link}>{link.link.substring(0, 47)}...</Typography>
                        <div className={classes.rightContainer}>
                          <Button className={classes.expandButtons} onClick={() => handleExpandedSubPartnershipAccordionChange(link.link, link.shortCode)}>
                            <LinkDetailsIcon width={10} />
                          </Button>
                          <Button onClick={(event) => handleLinkCopy(event, link.link)} className={classes.copyButtons}>
                            <CopyIcon width={10} />
                          </Button>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_435}</b>: {linkInfo.broker}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_436}</b>: {linkInfo.language}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_437}</b>: {linkInfo.shortCode}
                            </Typography>
                            <Button onClick={(event) => handleLinkCopy(event, linkInfo.shortCode)} className={classes.copyCodeButtons}>
                              <CopyIcon width={10} />
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_438}</b>: {linkInfo.percentage}
                            </Typography>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Fragment>
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
        {mlmLinks.length > 0 && (
          <Grid item xs={12} className={classes.selectGridContaner}>
            <Accordion className={classes.accordionContainer}>
              <AccordionSummary expandIcon={<ArrowDropDownSharp />} className={classes.accordionHeader}>
                <Typography className={classes.accordionHeaderTitle}>{translation.mlmLinks}</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetails }}>
                {mlmLinks.map((link) => (
                  <Fragment key={link.shortCode}>
                    <Accordion classes={{ root: classes.accordion }} expanded={expandedMlmLink === link.link} onChange={handleExpandedMlmAccordionChange(link.link, link.shortCode)}>
                      <AccordionSummary classes={{ content: classes.accordionContent }}>
                        <Typography className={classes.link}>{link.link.substring(0, 47)}...</Typography>
                        <div className={classes.rightContainer}>
                          <Button className={classes.expandButtons} onClick={() => handleExpandedMlmAccordionChange(link.link, link.shortCode)}>
                            <LinkDetailsIcon width={10} />
                          </Button>
                          <Button onClick={(event) => handleLinkCopy(event, link.link)} className={classes.copyButtons}>
                            <CopyIcon width={10} />
                          </Button>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_435}</b>: {linkInfo.broker}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_436}</b>: {linkInfo.language}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_437}</b>: {linkInfo.shortCode}
                            </Typography>
                            <Button onClick={(event) => handleLinkCopy(event, linkInfo.shortCode)} className={classes.copyCodeButtons}>
                              <CopyIcon width={10} />
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography component="span">
                              <b>{translation.text_438}</b>: {linkInfo.percentage}
                            </Typography>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Fragment>
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
      </Grid>
    </>
  );
});
