import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { FC, useCallback, useState, useEffect } from 'react';
import { useCommonStyles } from '../../styles';
import { useStyles } from './styles';
import { useAppData } from '../../providers';

interface Props {
  headerCells: any;
  rows: any[];
  emptyDataMessage?: string;
  totalRow?: any;
  columnsNumber: number;
  className?: string;
  headerClassName?: string;
  hidePagination?: boolean;
  rowsCountNumber?: number;
}

export const CustomTable: FC<Props> = ({
  headerCells,
  rowsCountNumber,
  rows,
  emptyDataMessage,
  totalRow,
  columnsNumber,
  className,
  headerClassName,
  hidePagination,
}) => {
  const rowCount = rowsCountNumber || 40;
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { translations } = useAppData();
  const [rowsNumber, setRowsNumber] = useState(rowCount);
  const [translation, setTranslation] = useState<any>([]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  const handleShowMore = useCallback(() => {
    const newNumber = rowsNumber + rowCount;
    setRowsNumber(newNumber);
  }, [rowsNumber, rowCount]);

  return (
    <>
      <TableContainer className={className}>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow
              className={`${commonClasses.tableHeader} ${headerClassName}`}
            >
              {headerCells}
            </TableRow>
          </TableHead>
          <TableBody>
            {totalRow}
            {rows.length === 0 ? (
              <TableRow className={commonClasses.tableRow}>
                <TableCell
                  align="center"
                  className={classes.emptyMessage}
                  colSpan={columnsNumber}
                >
                  {emptyDataMessage}
                </TableCell>
              </TableRow>
            ) : (
              rows.slice(0, rowsNumber).map((row) => row)
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {rows.length > 0 && !hidePagination && rows.length > rowsNumber && (
        <Grid
          container
          direction="column"
          alignContent="center"
          alignItems="center"
          className={classes.margin}
        >
          <Grid item>
            <p>
              {translation.text_6436}{' '}
              {`1 - ${
                rows.length > rowsNumber
                  ? `${rowsNumber} ${translation.of} ${rows.length}`
                  : rows.length
              }`}{' '}
              {rows.length > 1
                ? `${translation.text_6438}`
                : `${translation.text_6437}`}
            </p>
          </Grid>
          {rows.length > rowsNumber && (
            <Grid item>
              <Button
                variant="contained"
                className={`${commonClasses.buttonContained} ${classes.button}`}
                onClick={handleShowMore}
              >
                {translation.text_6439}
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};
