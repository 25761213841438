import React, { memo, FC, useCallback, useState, useEffect } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Alert } from '@material-ui/lab';
import { cloneDeep } from 'lodash';
import useStyles from './styles';
import { CopyIcon } from '../../CustomIcons';
import { Broker, ValueLabel } from '../../../models';
import { InputField, SelectField } from '../../Form';
import { generatePartnershipLink } from '../../../pages';
import { useApiError } from '../../../providers';
import { LightTooltip } from '../../LightTooltip';
import { useCommonStyles } from '../../../styles';
import { LinkConfiguration } from '../PartnershipLinkDialogModel';
import useGetTranslatedOptions from '../useGetTranslatedOptions';
import { trackAmplitudeData } from '../../../services/amplitudeService';

interface Props {
  partnershipId: number;
  brokers: Broker[];
  translation: any;
  linkConfigurations: LinkConfiguration[];
  setLoading: (value: boolean) => void;
  formRef: any;
  onLinkGeneration: (partnershipId: number) => void;
}

export const CreatePartnershipLinkTab: FC<Props> = memo(({ partnershipId, brokers, linkConfigurations, setLoading, formRef, translation, onLinkGeneration }) => {
  const commonClasses = useCommonStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { addError } = useApiError();
  const [brokerOptions, setBrokerOptions] = useState<ValueLabel[]>([]);
  const [subIBOptions, setSubIBOptions] = useState<ValueLabel[]>([]);
  const [generatedLink, setGeneratedLink] = useState<string>('');
  const classes = useStyles();
  const getOptions = useGetTranslatedOptions(translation);

  useEffect(() => {
    setBrokerOptions(getOptions(brokers, 'name', 'id'));
    setSubIBOptions(getOptions(linkConfigurations, 'schemeName', 'configurationId'));
  }, [linkConfigurations, brokers, getOptions]);

  const handleLinkGeneration = useCallback(
    async (values, { setSubmitting, setFieldValue }) => {
      const transformedValues = cloneDeep(values);

      Object.keys(transformedValues).forEach((key) => {
        if (transformedValues[key] === '-1') {
          transformedValues[key] = null;
        }
      });
      setLoading(true);

      generatePartnershipLink(transformedValues, partnershipId)
        .then((response) => {
          const eventProperties = {
            linkType: 'SubPartnership link',
          };
          trackAmplitudeData('partnerPortal_action_regLinkCreation', eventProperties);
          setGeneratedLink(response.data.link);
          setFieldValue('shortCode', '');
          onLinkGeneration(partnershipId);
        })
        .catch((error) => {
          addError(JSON.stringify(error.response));
        })
        .finally(() => {
          setLoading(false);
          setSubmitting(false);
        });
    },
    [addError, onLinkGeneration, partnershipId, setLoading],
  );

  const validationSchema = Yup.object().shape({
    brokerId: Yup.string().required(translation.text_586),
    linkConfigurationId: Yup.string().required(translation.text_586),
    shortCode: Yup.string().max(50, 'Too long'),
  });

  const handleLinkCopy = useCallback(async () => {
    await navigator.clipboard.writeText(generatedLink);
    enqueueSnackbar(translation.text_1637, { variant: 'success' });

    const eventProperties = {
      linkType: 'link copied',
    };
    trackAmplitudeData('partnerPortal_action_regLinkCopy', eventProperties);
  }, [enqueueSnackbar, generatedLink, translation]);

  const submitForm = useCallback(() => {
    formRef?.current?.submitForm();
  }, [formRef]);

  return (
    <>
      <Grid container spacing={2} className={classes.container}>
        <Formik
          innerRef={formRef}
          onSubmit={handleLinkGeneration}
          enableReinitialize
          initialValues={{
            brokerId: '',
            linkConfigurationId: '',
            shortCode: '',
          }}
          validationSchema={validationSchema}
        >
          {({ touched, errors }) => (
            <>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <SelectField
                    name="brokerId"
                    label={translation.text_1629}
                    placeholder={translation.text_1630}
                    options={brokerOptions}
                    errors={touched.brokerId ? errors.brokerId : undefined}
                    isRequired
                    selectClass={classes.selectField}
                    selectIconClass={classes.selectIconClass}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <SelectField
                    name="linkConfigurationId"
                    label={translation.text_579}
                    errors={touched.linkConfigurationId ? errors.linkConfigurationId : undefined}
                    options={subIBOptions}
                    isRequired
                    selectClass={classes.selectField}
                    selectIconClass={classes.selectIconClass}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.shortcodeContainer}>
                  <InputField
                    name="shortCode"
                    label={translation.text_1631}
                    icon={
                      <LightTooltip title={translation.text_1632}>
                        <InfoOutlined className={classes.input} />
                      </LightTooltip>
                    }
                    errors={touched.shortCode ? errors.shortCode : undefined}
                    inputClass={classes.inputRoot}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Grid className={classes.generateButtonContainer}>
                  <Button variant="contained" disableRipple onClick={submitForm} className={`${commonClasses.buttonContained} ${classes.button} ${classes.generateButton}`}>
                    {translation.text_1633}
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Alert icon={false} severity="success" className={classes.generatedLinkContainer}>
                  <div className={classes.link}>
                    <Typography component="span" className={classes.generetedLinkTittle}>
                      {translation.text_1634}:{' '}
                    </Typography>
                    {generatedLink !== '' && (
                      <Typography component="span" className={classes.generatedLink}>
                        {generatedLink.substring(0, 37)}...
                      </Typography>
                    )}
                  </div>
                  {generatedLink !== '' ? (
                    <div className={classes.rightContainer}>
                      <Button className={classes.copyLinkButton} onClick={handleLinkCopy}>
                        <CopyIcon />{' '}
                        <Typography component="span" className={classes.copyText}>
                          {translation.text_1635}
                        </Typography>
                      </Button>
                    </div>
                  ) : (
                    <div className={classes.link}>
                      <Typography component="span" className={classes.generetedLinkDefaultText}>
                        {translation.text_1636}
                      </Typography>
                    </div>
                  )}
                </Alert>
              </Grid>
            </>
          )}
        </Formik>
      </Grid>
    </>
  );
});
