import React, { FC, useCallback } from 'react';
import { ListItem, List, Button, SvgIconTypeMap, Collapse } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useStyles } from '../styles';
import { SubMenuItem } from './SubMenuItem';

interface MainMenuItemProps {
  name: string;
  Icon?: OverridableComponent<SvgIconTypeMap>;
  link?: string;
  items?: any[];
  selectedItem: string;
  subItemsLinks?: string[];
  handleSelectedItem: (item: string) => void;
}

export const MainMenuItem: FC<MainMenuItemProps> = ({ name, Icon, link, items, subItemsLinks, selectedItem, handleSelectedItem }) => {
  const classes = useStyles();
  const location = useLocation();
  const isExpandable = items && items.length > 0;

  const handleClick = useCallback(
    (menuItem: string) => {
      if (selectedItem === menuItem) {
        handleSelectedItem('');
      } else {
        handleSelectedItem(menuItem);
      }
    },
    [selectedItem, handleSelectedItem],
  );

  return (
    <>
      {link ? (
        <ListItem component={Link} key={name} to={link || ''} className={classes.listItem}>
          <Button
            variant="contained"
            className={`${classes.menuButton} 
              ${location.pathname === link || (subItemsLinks && subItemsLinks.includes(location.pathname)) ? classes.menuButtonSelected : ''}`}
            startIcon={Icon ? <Icon /> : null}
            onClick={() => handleClick(name)}
          >
            {name}
          </Button>
        </ListItem>
      ) : (
        <div className={classes.collapsableMenuItem}>
          <ListItem className={classes.listItemExpand} key={name}>
            <Button
              variant="contained"
              className={`${classes.menuButton} ${selectedItem === name && name !== 'Settings' ? classes.menuButtonSelected : ''}`}
              startIcon={Icon ? <Icon /> : null}
              onClick={() => handleClick(name)}
              endIcon={isExpandable && name !== selectedItem ? <ExpandMore /> : isExpandable && name === selectedItem && <ExpandLess />}
            >
              {name}
            </Button>
          </ListItem>
          {/* sub menu items */}
          {items && items.length > 0 && (
            <Collapse in={selectedItem === name} timeout="auto" unmountOnExit>
              <List component="div" className={classes.subList}>
                {items.map((item) => (
                  <SubMenuItem name={item.name} link={item.link} Icon={item.Icon} />
                ))}
              </List>
            </Collapse>
          )}
        </div>
      )}
    </>
  );
};
