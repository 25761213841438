import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import moment from 'moment';
import { ReportDetailsModel, ReportModel } from 'src/models';
import { getTranslation } from 'src/utils';
import { useAppData, useLoading } from '../../../providers';
import { ReportsGeneralToolbar, ReportTitle, ColumnsSelect } from '../../../components';
import { useStyles } from './styles';
import { MobilePartnershipReports, PartnershipReports, MobileCommissionsDetailedTable } from './components';
import useGetReports from './hooks/useGetReports';
import useSendEmail from './hooks/useSendEmail';
import useGetSummaryByClient from './hooks/useGetSummaryByClient';

export const CommissionsByClient: FC = () => {
  const currentDate = useMemo(() => new Date(), []);
  const classes = useStyles();
  const { userData, translations } = useAppData();
  const { setIsLoading } = useLoading();
  const [fromDate, setFromDate] = useState<Date>(new Date(`${currentDate.getMonth() + 1} 1 ${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`));
  const [toDate, setToDate] = useState<Date>(currentDate);
  const [reports, setReports] = useState<ReportModel[]>([]);
  const [showReports, setShowReports] = useState<boolean>(false);
  const [loginData, setLoginData] = useState<any>(null);
  const [summaryData, setSummaryData] = useState<ReportDetailsModel[]>();
  const [selectedPartnership, setSelectedPartnership] = useState<string>('');
  const [translation, setTranslation] = useState<any>([]);
  const columnNames = useMemo(
    () => [translation.text_6625, translation.text_6626, translation.text_6627, translation.text_6628, translation.text_6629, translation.text_6630, translation.text_6631, translation.text_6632, translation.text_6633],
    [translation],
  );
  const [selected, setSelected] = useState<string[]>(columnNames);

  const dateRange = useMemo(
    () =>
      `
      ${getTranslation(translation, moment(fromDate).format('MMMM'))}  ${moment(fromDate).format('YYYY')} - 
      ${getTranslation(translation, moment(toDate).format('MMMM'))}  ${moment(toDate).format('YYYY')}      
      `,
    [fromDate, toDate, translation],
  );

  useEffect(() => {
    if (columnNames.length === 9) {
      setSelected(columnNames.slice(0, 6));
    }
  }, [columnNames]);

  useEffect(() => {
    const userLoginData = JSON.parse(userData);
    setLoginData(userLoginData);
  }, [userData]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  const handleNewReportRequest = useGetReports({
    toDate,
    fromDate,
    setReports,
    showReports,
    setShowReports,
    setIsLoading,
  });

  const changeFromDate = useCallback((newValue) => {
    setFromDate(newValue);
  }, []);

  const changeToDate = useCallback((newValue) => {
    setToDate(newValue);
  }, []);

  const renderTableData = (): string[][] => {
    const rows: string[][] = [];
    reports.forEach((row) => {
      const tableRow: string[] = [
        row.partnershipCode ? row.partnershipCode : '-',
        row.reportTotal.rebateUSD || row.reportTotal.rebateUSD === 0 ? row.reportTotal.rebateUSD.toFixed(2) : '-',
        row.totalRebatesUSD || row.totalRebatesUSD === 0 ? row.totalRebatesUSD.toFixed(2) : '-',
        row.paymentUSD || row.paymentUSD === 0 ? row.paymentUSD.toFixed(2) : '-',
        row.reportTotal.profitUSD || row.reportTotal.profitUSD === 0 ? row.reportTotal.profitUSD.toFixed(2) : '-',
        row.partnershipType ? row.partnershipType : '-',
        row.schemeName ? row.schemeName : '-',
        row.schemeType ? row.schemeType : '-',
        row.subPartnershipsRebateSum || row.subPartnershipsRebateSum === 0 ? row.subPartnershipsRebateSum.toFixed(2) : '-',
      ];
      rows.push(tableRow);
    });

    return rows;
  };

  const getSummaryData = useGetSummaryByClient({
    toDate,
    fromDate,
    setSummaryData,
    setIsLoading,
  });

  const handleNextButtonClick = useCallback(
    async (row: any) => {
      await getSummaryData(row.partnershipCode);
      setSelectedPartnership(row.partnershipCode);
    },
    [getSummaryData],
  );

  const renderDetailedTitle = useCallback(
    (): ReactNode => (
      <>
        <b>
          {loginData.userDisplayName} ({selectedPartnership})
        </b>
        &nbsp;
        {getTranslation(translation, moment(fromDate).format('MMMM'))} {moment(fromDate).format('YYYY')} - {getTranslation(translation, moment(toDate).format('MMMM'))} {moment(toDate).format('YYYY')}
      </>
    ),
    [loginData, fromDate, toDate, selectedPartnership, translation],
  );

  const sendEmail = useSendEmail({
    toDate,
    fromDate,
    selected,
    reportColumns: columnNames,
  });

  return (
    <>
      {summaryData ? (
        <MobileCommissionsDetailedTable
          toDate={toDate}
          fromDate={fromDate}
          partnershipCode={selectedPartnership}
          title={translation.text_6450}
          subTitle={renderDetailedTitle()}
          dateFrom={fromDate}
          data={summaryData}
          onBackClick={() => setSummaryData(undefined)}
        />
      ) : (
        <Grid container direction="column" className={classes.root}>
          <ReportTitle
            title={translation.text_6450}
            exportFileName={translation.text_6450}
            reportColumns={columnNames}
            exportTableData={renderTableData()}
            printColumnNumber={selected.length}
            content={<PartnershipReports toDate={toDate} fromDate={fromDate} partnerName={loginData?.userDisplayName} date={dateRange} dateFrom={fromDate} columnNames={columnNames} selectedColumns={selected} rows={reports || []} />}
            enableButtons={showReports}
            translation={translation}
            sendEmail={sendEmail}
            reportNameInEn="Commissions By Client"
          />
          <Paper className={classes.paper}>
            <Grid container direction="column" className={classes.root}>
              <Grid container direction="row" className={classes.desktop}>
                <Grid item xs={9}>
                  <ReportsGeneralToolbar fromDate={fromDate} toDate={toDate} handleFromDateChange={changeFromDate} handleToDateChange={changeToDate} showButton handleButtonClick={handleNewReportRequest} />
                </Grid>
                <Grid item xs={3}>
                  <ColumnsSelect reportColumns={columnNames} selectedColumns={selected} handleSelectColumns={setSelected} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item className={classes.mobile}>
              <ReportsGeneralToolbar fromDate={fromDate} toDate={toDate} handleFromDateChange={changeFromDate} handleToDateChange={changeToDate} showButton handleButtonClick={handleNewReportRequest} />
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={6} className={classes.mobile}>
                <ColumnsSelect reportColumns={columnNames} selectedColumns={selected} handleSelectColumns={setSelected} />
              </Grid>

              {loginData && (
                <>
                  <Grid className={classes.reportTable}>
                    <PartnershipReports toDate={toDate} fromDate={fromDate} partnerName={loginData.userDisplayName} date={dateRange} dateFrom={fromDate} columnNames={columnNames} selectedColumns={selected} rows={reports} />
                  </Grid>
                  <Grid className={classes.reportTableMobile}>
                    <MobilePartnershipReports columnNames={columnNames} selectedColumns={selected} rows={reports} onNextClick={handleNextButtonClick} />
                  </Grid>
                </>
              )}
            </Grid>
            {!showReports && (
              <Grid container direction="column" justify="center" alignItems="center" className={classes.emptyReportMessageContainer}>
                <Grid item className={classes.groupedByCell}>
                  {translation.text_6455}
                </Grid>
                <Grid item>{translation.text_6456}</Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
      )}
    </>
  );
};
